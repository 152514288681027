import { ArrowLeft, ArrowRight, Printer } from "react-feather";
import { Button, Spinner } from "../../../../../lib/components";
import React, { useEffect, useState } from "react";

import Logo from "assets/images/jabiLogo/MAJOR_DETOUR.png";
import moment from "moment";
import styles from "assets/scss/pages/menu.module.scss";
import { useHistory } from "react-router-dom";
import { useSidebar } from "lib/context/dashboard/sidebar.context";
import useUi from "hooks/ui.hook";
import { sortRepas, isDayEmpty } from "../../helpers/operations";
import { useMenu } from "../../router_context";
import { ModalReservation, RepasComponent, DaySelector, ModalResume } from "../../router_components";

const MenuVisualize = () => {
  const [ctx, dispatch] = useMenu();
  const [ui] = useUi();
  const history = useHistory();
  const [focused, setFocused] = useState(null);
  const [sidebar] = useSidebar();


  if (ctx.data === null) return <Spinner />;
  if (ctx.loading) return <Spinner />;

  return (
    <div className={styles.absoluteContainer}>
      <div className={styles.globalContainer}>
        <div className={styles.container}>
          <div className={styles.choseMenu}>
            <DaySelector />
          </div>
          <div className={`${styles.container} ${sidebar ? styles.sidebarOpen : ""}`}>
            <div className={styles.repasContainer}>
              {isDayEmpty(ui.establishment.template, ctx.data[ctx.selectedDate]) ?
                <div className={styles.noRepas}>
                  Aucun Repas renseigné pour ce jour.
                </div>
                :
                <>
                  {ui.establishment.template ? sortRepas(ui.establishment.template, Object.keys(ui.establishment.template)).map((_repas, id) => (
                    <div key={id} className={styles.repasComponentContainer}>
                      <RepasComponent repas={_repas} />
                    </div>
                  )) : null}
                </>
              }

            </div>
          </div>
          {!isDayEmpty(ui.establishment.template, ctx.data[ctx.selectedDate]) ?
            <>
              <ModalResume />
              <ModalReservation />
            </>
            : null}
        </div>
      </div>
    </div>

  );
};

export default MenuVisualize;
