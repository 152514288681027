import React from "react";
import { MenuProvider } from "./router_context";
import { MenuVisualize } from "./router_pages";

const Menu = () => {
  return (
    <MenuProvider>
      <MenuVisualize />
    </MenuProvider>
  );
};

export default Menu;