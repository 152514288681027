import React, {useState} from "react";
import { useParams } from "react-router-dom";
import {Provider} from "./ctx";
import {Tab} from "../../../lib/components";

import {UserInfos, UserBook, UserActivity} from "./User";

const UserHOC = () => {
  const {id} = useParams();
  const [activeTab, setActiveTab] = useState(0);

  return (
    <Provider id={id}>
      <div style={{width: "100%"}}>
        <Tab
          activeTab={activeTab}
          onClick={setActiveTab}
          render={[
            {title: "Infos", content: <UserInfos />},
            {title: "Livre d'or", content: <UserBook />},
            //{title: "Activité", content: <UserActivity />},
          ]}
          />
      </div>
    </Provider>
  );
};

export default UserHOC;