import Answering from "./Answering";
import DisplayAnswer from "./DisplayAnswer";

const TrueFalse = {
  name: "Vrai ou Faux",
  slug: "true_false",
  Answering,
  DisplayAnswer,
};

export default TrueFalse;