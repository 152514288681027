import React, {createContext, useReducer, useEffect, useContext, useCallback, useState} from "react";
import PropTypes from "prop-types";
import firebase from "services/firebase";
import {toast} from "react-toastify";
import debounce from "lodash.debounce";
import useUI from "hooks/ui.hook";

const firestore = firebase.firestore();

const Ctx = createContext();

const Reducer = (state, action) => {
  switch(action.type) {
  case "setIsLoading":
    return {...state, isLoading: action.isLoading};
  case "setUser":
    return {...state, user: action.user};
  case "setGroupement":
    return {...state, groupement: action.groupement};
  case "addBook":
    return {...state, book: [action.book, ...state.book]};
  case "removeBook":
    return {...state, book: state.book.filter(i => i.uid !== action.uid)};
  case "updateBook":
    return {...state, book: state.book.map(i => i.uid === action.uid ? {...i, content: action.content} : i)};
  case "setCtx":
    return {...action.ctx};
  default:
    return {...state};
  }
};

const Default = {
  isLoading: true,
  error: {},
  state: null,

  //fonctionnement
  establishment: null,//current establishment of user
  groupement: null,//current groupement of user establishment
};

const Provider = ({children, id}) => {
  const [ui] = useUI();
  const [state, dispatch] = useReducer(Reducer, Default);

  useEffect(() => {
    (async () => {
      try {
        const user = await firestore.collection("users").doc(id).get();
        const establishment = await firestore.collection("establishments").doc(user.data().establishment).get();
        const groupement = await firestore.collection("groupements").where("establishments", "array-contains", establishment.id).get();
        const book = await firestore.collection("users").doc(id).collection("book").get();

        const data = user.data();

        dispatch({
          type: "setCtx", 
          ctx: {
            user: {
              uid: user.id,
              ...data,
              createdAt: data.createdAt ? data.createdAt.toDate() : null,
              lastLogin: data.lastLogin ? data.lastLogin.toDate() : null,
              birthdate: data.birthdate ? data.birthdate.toDate() : null,
            },
            establishment: {uid: establishment.id, ...establishment.data()},
            groupement: groupement.empty ? null : {uid: groupement.docs[0].id, ...groupement.docs[0].data()},
            book: book.docs.map(doc => ({uid: doc.id, ...doc.data()})).map(i => ({...i, createdAt: i.createdAt.toDate()})),
            isLoading: false,
            error: {},
          }
        });
      } catch (error) {
        console.error("error", error);
      }
    })();
  }, []);

  const _public = {
    state,
    dispatch,
    save: async () => {
      try {
        const {role, ...user} = state.user;
        await firestore.collection("users").doc(id).update(user);
        toast.success("Utilisateur mis à jour");
      } catch (error) {
        console.log("error", error);
      }
    },

    addBook: async (content) => {
      const _book = {
        content,
        createdAt: new Date(),
        createdByUid: ui.user.uid,
        createdByName: ui.user.displayName,
      };
      await firestore.collection("users").doc(id).collection("book").add(_book);
      dispatch({type: "addBook", book: _book});
    },

    removeBook: async (uid) => {
      await firestore.collection("users").doc(id).collection("book").doc(uid).delete();
      dispatch({type: "removeBook", uid: uid});
      toast.success("Message supprimé");
    },

    updateBook: async (uid, content) => {
      await firestore.collection("users").doc(id).collection("book").doc(uid).update({content});
      dispatch({type: "updateBook", content, uid});
      toast.success("Message mis à jour");
    }
  };

  return (
    <Ctx.Provider value={_public}>
      {children}
    </Ctx.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
};

const useCtx = () => {
  const ctx = useContext(Ctx);
  if (ctx === undefined) {
    throw new Error("useCtx must be used within a Provider");
  }
  return ctx;
};

export {Ctx, Provider, useCtx};
export default useCtx;
