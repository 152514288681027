
const filterRepas = (template, list) => {
  return list.filter((a) => Object.keys(template).includes(a));
};

const sortRepas = (template, list) => {
    return list.sort((a,b) => template[a].heure - template[b].heure);
};

const sortMenus = (template, repas, list) => {
    return list.sort((a,b) => template[repas].menus[a].weight - template[repas].menus[b].weight);
};

const sortCategories = (template, repas, list) => {
    if(typeof(list[0]) === "object"){
        return list.sort(([aCateg, aObj],[bCateg, bObj]) => template[repas].categories.indexOf(aCateg) - template[repas].categories.indexOf(bCateg)); 
    }else{
        return list.sort((a,b) => template[repas].categories.indexOf(a) - template[repas].categories.indexOf(b)); 
    }
};

const sortDishes = (list) => {
    return list.sort(([aId,a],[bId,b]) => a.order - b.order); 
};

const sortDishesWithMenu = (template, repas, list) => {
  return list.sort(([aId,a],[bId,b]) => template[repas].menus[a.origin].weight === template[repas].menus[b.origin].weight ? a.order - b.order : template[repas].menus[a.origin].weight - template[repas].menus[b.origin].weight); 
};

const filterDishByMenu = (list, menu) => {
    return list.filter(([aId,a]) => a.origin === menu); 
};

const isDayEmpty = (template, data) => {
    const repasList = Object.keys(template);
    
    let retour = true;
      if (data) {
        Object.keys(data).forEach(repas => {
          if(repasList.includes(repas)){
            if (data[repas]) {
              if (!isRepasEmpty(data[repas]))
                retour = false;
            }
          }
        });
      }
    return retour;
};

const isRepasEmpty = (data) => {
    let retour = true;
      if(data){
        Object.keys(data).forEach(_category => {
            if(!isCategoryEmpty(data[_category])){
              retour = false;
            }
        });
      }
    return retour;
};

const isMenuEmpty = (data, menu) => {
  let retour = true;
  if(data){
    Object.values(data).forEach(_category => {
      Object.values(_category).forEach(_dish => {
        if(_dish.origin === menu){
          retour = false;
        }
      });
    });
  }
return retour;
};

const isCategoryEmpty = (data) => {
  if(data){
    if(typeof(data) === "object"){
      return Object.keys(data).length > 0 ? false : true;
    }else{
      if(data != null && data != undefined) return false;
      else return true;
    }
  }else{
    return true;
  }
};

const hasBooked = (data, uid, repas) => {
    let retour = false;
          if (data.reservation) {
            if (data.reservation[uid]) {
              if (data.reservation[uid][repas]) {
                if (
                    data.reservation[uid][repas].length > 0
                )
                  retour = true;
              }
            }
          }
    return retour;
  };

const getDefaultRepas = (template, data, repas) => {
  if (!data || !template || !repas || !template[repas].menus || !data[repas]) return null;

  const dailyRepas = data[repas];
  // on récupère les différents menus du repas dans le template, et on les classes par priorité.
  const _defaultMenus = Object.entries(template[repas].menus).sort((a, b) => a[1].weight - b[1].weight).map(i => i[0]);
  
  // On détérmine les plats prioritaires.
  const defaultResa = Object.entries(dailyRepas).map(([repas, value]) => {
    const defaultMenu = _defaultMenus.find((defaultMenu) => {
      const _defaultPlat = Object.entries(value).filter(([, value]) => value.origin === defaultMenu)
                                                .sort(([, a], [, b]) => a.order - b.order)
                                                .map(([key, value]) => ({ key, ...value }));
      return _defaultPlat.length > 0;
    });
    return defaultMenu ? {
      repas,
      ...(Object.entries(value).filter(([, value]) => value.origin === defaultMenu).sort(([, a], [, b]) => a.order - b.order).map(([key, value]) => ({ key, ...value }))[0])
    } : { repas };
  });

  return defaultResa.reduce((acc, { repas, ...rest }) => ({ ...acc, [repas]: rest?.key ?? null }), {});
};

const getDefaultDay = (template, data) => {
  if (!data || !template) return null;

  const _repas = Object.keys(template);

  let defaultDay = {};

  _repas.forEach(_r => {
    const _defaultRepas = getDefaultRepas(template, data, _r);
    defaultDay[_r] = _defaultRepas;
  });

  return defaultDay;
};

const areReservationsEqual = (_resa1, _resa2) => {

  if(_resa1 && _resa2){
    let retour = true;

    Object.entries(_resa1).forEach(([_repas, _list]) => {
      if(!_resa2[_repas]){
        retour = false;
      }else{
        if(_list.length !== _resa2[_repas].length){
          retour = false;
        }else{
          _list.forEach((_resa, indx) => {

            Object.entries(_resa).forEach(([_dish, value]) => {
                if(_resa2[_repas][indx][_dish] !== value){
                  retour = false;
                }
            });
          
          });
        }
      }

    });

    return retour;
  }
  else{
    if(_resa1 != _resa2){
      return false;
    }else{
      return true;
    }
  }

};

const isReservationEmpty = (template, repas, _reservation) => {
  if(template && repas && template[repas] && _reservation && _reservation.length > 0){

    let retour = true;
    template[repas].categories.forEach(_categ => {
      if(_reservation[0][_categ])retour = false;
    });

    return retour;
  }else{
    return true;
  }

};


const deepMenuEqual = (x, y) => {
  if((x !== null && typeof(x) === "object") && (y !== null && typeof(y) === "object")){
    if (Object.keys(x).length !== Object.keys(y).length){
      return false;
    }else{
      if(Object.keys(x).length > 0){
          let result = true;
          Object.keys(x).forEach(element => {
            if (deepMenuEqual(x[element], y[element]) === false){
              result = false;
            }
        });
        return result;
      }else{
        return true;
      }
    }
  }else{
    if (x === y) {
      return true;
    }else{
      return false;
    }
  }
};

export {filterRepas, sortRepas,sortMenus,sortCategories, sortDishes, sortDishesWithMenu, filterDishByMenu, hasBooked, isDayEmpty, isRepasEmpty, isMenuEmpty, isCategoryEmpty, getDefaultRepas, getDefaultDay, areReservationsEqual, isReservationEmpty, deepMenuEqual};