import React, {useState} from "react";
import {Card, FormGroup, Input, Button} from "lib/components";

import {toast} from "react-toastify";
import firebase from "services/firebase";
import useCtx from "./context";
import { ArrowLeft } from "react-feather";

const firestore = firebase.firestore;
const auth = firebase.auth;

const colors = [ "#ed1c24", "#f15a24", "#f7931e", "#f1b71c", "#fcee21", "#8cc63f", "#22b573", "#29abe2", "#0071bc", "#2e3192", "#662d91", "#c1272d"];

const Password = () => {
  const [ctx, dispatch] = useCtx();
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    setLoading(true);
    let errCount = 0;

    if (!ctx.password) {
      errCount++;
      toast.error("Le mot de passe est requis");
    }
    if (!ctx.confirm) {
      errCount++;
      toast.error("La confirmation est requise");
    }
    if (ctx.password !== ctx.confirm) {
      errCount++;
      toast.error("Les mots de passe ne correspondent pas");
    }
    if (ctx.password && ctx.confirm < 6) {
      errCount++;
      toast.error("Le mot de passe doit être supérieur à 6 caractères.");
    }
    if (errCount > 0) {
      setLoading(false);
      return;
    }

    let user;
    try {
      user = await auth().createUserWithEmailAndPassword(ctx.mail, ctx.password);
    } catch (e) {
      if (e.code === "auth/email-already-in-use") {
        toast.error("L'adresse email est déja utilisée.");
        return;
      }
    }

    let strIdSenior = user.user.uid;

    let payload = {
      anonymous: false,
      role: ctx.role,
      name: ctx.name.toUpperCase(),
      surname: ctx.surname,
      createdAt: new Date(),
      verified: true,
      establishment: ctx.establishmentId,
      refSenior: strIdSenior.substring(3, 8),
      refSeniorId: ctx.refSeniorId,
      color: colors[Math.floor(Math.random() * colors.length)],
    };
    try {
      await firestore().collection("users").doc(user.user.uid).set(payload);
      dispatch({type:"navigate", step: "Register_Welcome"});
    } catch (e) {
      toast.error(e?.message ?? "Une erreur est survenue");
      console.error(e);
    }
  };

  return (
    <div className="flex justify-center items-center w-full" style={{height: "calc(100vh - 90px)"}}>
      <Card color="white">
        <ArrowLeft className="cursor-pointer" onClick={() => dispatch({type: "navigate", step: "Register_Name"})} />
        <h3 className="text-center">Inscription 4/4</h3>
        <FormGroup>
          <Input type="text" label="email" value={ctx.mail} onChange={e => dispatch({type: "changeMail", mail: e.target.value.trim()})} />
        </FormGroup>
        <FormGroup>
          <Input type="password" label="Mot de passe" value={ctx.password} onChange={e => dispatch({type: "changePassword", password: e.target.value})} />
        </FormGroup>
        <FormGroup>
          <Input type="password" label="Confirmation" value={ctx.confirm} onChange={e => dispatch({type: "changeConfirm", confirm: e.target.value})} />
        </FormGroup>
        <FormGroup className="flex justify-center">
          <Button onClick={onSubmit} loading={loading}>Confirmer mon inscription</Button>
        </FormGroup>
      </Card>
    </div>
  );
};

export default Password;