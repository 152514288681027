import React, {useState, useEffect, useContext} from "react";
import { useParams } from "react-router-dom";
import useCultureQuizzInstance from "../../../hooks/useCultureQuizzInstance";
import { Button, Spinner } from "../../../lib/components";
import { useFullscreen } from "rooks";
import {SidebarContext} from "../../../lib/context/dashboard/sidebar.context";
import { useHistory } from "react-router-dom";

import podium1 from "assets/images/CultureQuizz/podium1.png";
import podium2 from "assets/images/CultureQuizz/podium2.png";
import podium3 from "assets/images/CultureQuizz/podium3.png";
import styles from "assets/scss/pages/CutlureQuizz/QuizzResults.module.scss";
import useUI from "../../../hooks/ui.hook";

const QuizzResults = () => {
  const [ui] = useUI();
  const params = useParams();
  const history = useHistory();
  const {
    loading,
    instance,
    quizz,
  } = useCultureQuizzInstance(params.uid);
  const {exit, isFullscreen} = useFullscreen();
  const [sidebarOpen, setSidebarOpen] = useContext(SidebarContext);
  const [results, setResults] = useState(null);

  useEffect(() => {
    if(instance && instance.participants){
      let _participants = Object.values(instance.participants);
      let newList = [];
      _participants.forEach(_uid => {
        let counter = 0;
        if(instance.answers){
          Object.values(instance.answers).forEach(a => {
            let _answer = a[_uid];
            if (_answer && _answer.isCorrect) { 
              counter++;
            }
          });
        }
      
        newList.push({uid: _uid, score: counter});
      });
      //sort descending by score
      newList.sort((a, b) => {return b.score - a.score;});

      newList.forEach((element, index) => {
        element.classement = index+1;

        if(element.uid === ui.user.uid){
          setResults(element);
        }
      });
      
    }

  }, [quizz, instance]);
  
  const exitFullScreen = () => {
    if (isFullscreen) exit();
    if(!sidebarOpen) setSidebarOpen(true);
  };

  const exitQuiz = () => {
    exitFullScreen();
    history.push("/dashboard/cultureQuizz");
  };
  
  console.log("res",results);
  if (loading) return <Spinner />;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerTitle}><h2>Fin de la partie</h2></div>
      </div>
      <div className={styles.podiums}>
        <div className={`${styles.podium} ${styles.podium1}`}>
          {results.classement <= 3 ? <img src={results.classement === 1 ? podium1 : results.classement === 2 ? podium2 : results.classement === 3 ? podium3 : podium1} alt="podium1" /> : <div className={styles.classement}>{results.classement}ème / {Object.keys(instance.participants).length}</div>}
          <p className={styles.score}>score : {results && results.score ? `${results.score}/${instance.currentQuestion - 1}` : "0/"+(instance.currentQuestion - 1) }</p>
        </div>

      </div>
      <div style={{position: "absolute", zIndex: 1001, top: -95,left: "70px", right: "165px", height: "80px", display: "flex", justifyContent: "center"}}>
        <div style={{position: "relative", width: "95%", height: "100%", display: "flex", justifyContent: "space-between", alignItems:"center"}}>
          <span></span>
          <span></span>
          <Button color="danger" onClick={exitQuiz} style={{fontSize: "1.2vw", fontWeight:"bold"}}>Quitter le quiz</Button>
        </div>
      </div>
    </div>
  );
};

export default QuizzResults;