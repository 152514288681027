import React, {useEffect, useState} from "react";
import QuestionResult from "../questionResult";
import styles from "./multipleChoice.module.scss";
import { Circle, Square, Star ,Triangle } from "react-feather";
import * as c from "../colors";
import { useParams } from "react-router-dom";
import useCultureQuizzInstance from "../../../../../hooks/useCultureQuizzInstance";
import useUI from "../../../../../hooks/ui.hook";

const Answering = () => {

  const Icons = [
    <Circle key="circle" stroke={c.COLOR_PALETTE_1[0]} width={65} height={65} fill={c.COLOR_PALETTE_1[0]} />,
    <Square key="square" stroke={c.COLOR_PALETTE_1[1]} width={65} height={65} fill={c.COLOR_PALETTE_1[1]} />,
    <Star key="star" stroke={c.COLOR_PALETTE_1[2]} width={65} height={65} fill={c.COLOR_PALETTE_1[2]} />,
    <Triangle key="triangle" stroke={c.COLOR_PALETTE_1[3]} width={65} height={65} fill={c.COLOR_PALETTE_1[3]} />
  ];

  const [ui] = useUI();
  const params = useParams();
  const {
    instance,
    question  } = useCultureQuizzInstance(params.uid);

  const [answer, setAnswer] = useState(null);

  useEffect(() => {
    if (instance && instance.answers && instance.answers[instance.currentQuestion] && instance.answers[instance.currentQuestion][ui.user.uid]){
      setAnswer(instance.answers[instance.currentQuestion][ui.user.uid].answer);
    }
  }, [instance]);


  const hasAnswered = answer == null ? false : true;

  if(!question)return <></>;

  return (
    <div className={styles.displayAnswer}>
      <div className={styles.title}>
        <h3>{question.title}</h3>
      </div>
      <div className={styles.mediaDiv}>
        <QuestionResult question={question}/>
      </div>
      <div className={styles.answers}>
        {question.answers.map((_answer, index) => (
          <div key={index} className={`${styles.answer}`} 
          style={{
            opacity: 0.25,
            ...(answer === index ? { backgroundColor: c.COLOR_QUIZZ_SELECTED } : {}),
            ...(question.answer === index ? { backgroundColor: c.COLOR_PALETTE_RESULT[0], opacity: hasAnswered ? 1 : 0.25 } : {})
          }}
          >
            {Icons[index]}
            <span>{_answer}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Answering;