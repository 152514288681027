import { Button, Card } from "../../../lib/components";
import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import useUI from "../../../hooks/ui.hook";

import PDF from "./_weekPlanningPdf";
import { PDFDownloadLink } from "@react-pdf/renderer";
import React from "react";
import sleep from "../../../helpers/asyncWait";

const PlanningWeekExport = () => {
  const dlButton = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const params = location.state;
  const [counter, setCounter] = useState(5);
  const [redirect, setRedirect] = useState(false);
  const [ui] = useUI();

  useEffect(() => {
    (async () => {
      await sleep(2000);
      if (dlButton.current) {
        dlButton.current.click();
        setRedirect(true);
      }
    })();
  }, []);

  useEffect(() => {
    if (!redirect) return;

    let interval = setInterval(() => {
      setCounter(counter - 1);
      if (counter === 0) {
        history.push("/dashboard/animations");
        clearInterval(interval);
      }
    }, 1000);
  }, [redirect, counter]);

  return (
    <Card
      style={{
        width: "100%",
        height: 300,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Button
          color="secondary"
          onClick={() => history.goBack()}
          style={{ marginRight: 5 }}
        >
          Retour
        </Button>
        <p style={{ marginTop: 10 }}>
          Votre fichier est en cours de téléchargement. Vous allez être redirigé
          dans {counter >= 0 ? counter : 0}
        </p>
      </div>
      <p>
        <span>Si le téléchargement ne commence pas automatiquement </span>
        <PDFDownloadLink
          document={
            <PDF
              ui={ui}
              monday={params.monday}
              events={params.events}
              logo={params.logo}
              additionnalData={JSON.parse(params.additionnalData)}
            />
          }
          fileName="planning.pdf"
        >
          {({ /* blob, url,*/ loading /*, error */ }) =>
            loading ? (
              "Loading document..."
            ) : (
              <>
                <span ref={dlButton}>Cliquez ici</span>
              </>
            )
          }
        </PDFDownloadLink>
      </p>
    </Card>
  );
};

export default PlanningWeekExport;
