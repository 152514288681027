import { HomeOutline, CalendarOutline, RestaurantOutline, NewspaperOutline, DocumentTextOutline, RibbonOutline, PlayOutline } from "react-ionicons";

import { faRss, faPrint } from "@fortawesome/free-solid-svg-icons";
import { SidebarImage, SidebarLink, SidebarSubMenu } from "../lib/components/dashboard";

import Logo from "../assets/images/jabiLogo/MAJOR_BLANC.png";
import React from "react";
import useUI from "../hooks/ui.hook";

const SidebarMenu = () => {
  const [ui] = useUI();

  return (
    <div style={{ position: "relative", top: -20 }}>
      <SidebarImage img={Logo} />
      <SidebarLink to="/dashboard/one" icon={HomeOutline}>
        Accueil
      </SidebarLink>


        {ui.establishment.features &&
          ui.establishment.features.includes("animationPlanning") ? (
          <SidebarLink to="/dashboard/animations" icon={CalendarOutline}>
              Animations
          </SidebarLink>
        ) : (
          <></>
        )}
      {ui.establishment.features &&
          ui.establishment.features.includes("menuPlanning") ? (
          <SidebarLink to="/dashboard/menu" icon={RestaurantOutline}>
              Menus
          </SidebarLink>
        ) : (
          <></>
        )}
      {ui.establishment.features &&
          ui.establishment.features.includes("news") ? (
          <SidebarLink to="/dashboard/news" icon={NewspaperOutline}>
              Actualités
          </SidebarLink>
        ) : (
          <></>
        )}
      {ui.establishment.features &&
            ui.establishment.features.includes("gazette") ? (
          <SidebarLink to="/dashboard/gazette" icon={DocumentTextOutline}>
                Gazette
          </SidebarLink>
        ) : (
          <></>
        )}

      {ui.establishment.features &&
      ui.establishment.features.includes("cultureQuizz") ? (
        <SidebarLink to="/dashboard/cultureQuizz" icon={RibbonOutline}>Culture Quiz</SidebarLink>
      ) : (
        <></>
      )}


      {/* {ui.user.role === "senior" ? (
        <SidebarLink to="/dashboard/jeux" icon={PlayOutline}>Jeux</SidebarLink>
      ) : (
        <></>
      )} */}

    </div>
  );
};

export default SidebarMenu;
