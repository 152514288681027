import React from "react";
import useUI from "../../../hooks/ui.hook";

import Basic_MenuVisualize from "./Basic/Pages/MenuVisualize";

import MarpaBergeDuLion_MenuVisualize from "./Specific/marpaBergesDuLion/Pages/MenuVisualize";


export const MenuVisualize = (props) => {
    const [ui] = useUI();

    if (ui?.establishement?.uid === "Q1K3DO8mh4BO8AAI6SvM") return <MarpaBergeDuLion_MenuVisualize {...props} />;

    return <Basic_MenuVisualize {...props} />;
};

