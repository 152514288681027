import React, {useState} from "react";
import {Card, Form, FormGroup, Input, Button} from "lib/components";
import {toast} from "react-toastify";
import useCtx from "./context";
import { ArrowLeft } from "react-feather";

const Name = () => {
  const [ctx, dispatch] = useCtx();

  const onSubmit = () => {
    if (ctx.name && ctx.surname) {
      dispatch({type: "navigate", step: "Register_Password"});
    } else {
      if (!ctx.name) {
        toast.error("Veuillez remplir le champs 'Nom'");
      }
      if (!ctx.surname) {
        toast.error("Veuillez remplir le champs 'Prénom'");
      }
    }
  };

  return (
    <div className="flex justify-center items-center w-full" style={{height: "calc(100vh - 90px)"}}>
      <Card color="white">
        <ArrowLeft className="cursor-pointer" onClick={() => dispatch({type: "navigate", step: "Register_Role"})} />
        <h3 className="text-center">Inscription 3/4</h3>
        <FormGroup>
          <Input type="text" label="Prénom" value={ctx.surname} onChange={e => dispatch({type: "changeSurname", surname: e.target.value})} />
        </FormGroup>
        <FormGroup>
          <Input type="text" label="Nom" value={ctx.name} onChange={e => dispatch({type: "changeName", name: e.target.value})} />
        </FormGroup>
        <FormGroup className="flex justify-center items-center mt-2">
          <Button onClick={onSubmit}>Enregistrer mes informations</Button>
        </FormGroup>
      </Card>
    </div>
  );
};

export default Name;
