import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {Input, Text, FormGroup, Select} from "../components";
import {Field} from "formik";

const PolymerField = (props) => {
  const {type, name, formgroup} = props;

  const Wrapper = formgroup ? FormGroup : Fragment;

  return (
    <Wrapper>
      { 
        type === "text" || type === "number" || type === "password" ||
      type === "tel" || type === "email" ?
          <Field name={name}>
            {({field, meta}) => (
              <Input {...field}
                {...props}
                invalid={(meta.touched && meta.error) ? meta.error : false} />
            )}
          </Field>
          :
          type === "select" ?
            <Field name={name}>
              {({field, meta}) => (
                <Select {...field}
                  {...props}
                  invalid={(meta.touched && meta.error) ? meta.error : false}
                  value={field.value ? JSON.parse(field.value) : null}
                  onChange={(e) => field.onChange(name)(JSON.stringify(e))} />
              )}
            </Field>
            :
      
            <Text color='var(--danger)'>Incorrect field type !</Text>
      }
    </Wrapper>
  );
};

PolymerField.propTypes = {
  type  : PropTypes.string,
  name  : PropTypes.string,
  formgroup  : PropTypes.any,
};

export default PolymerField;