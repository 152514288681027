import React from "react";
import { CustomInput } from "reactstrap";
import PropTypes from "prop-types";
import Label from "./Label";

const Checkbox = (props) => {
  const { label, checked, className, disabled, onChange } = props;

  return (
    <span className={`d-flex ${className}`}>
      <CustomInput
        type="checkbox"
        className={className ?? ""}
        checked={checked}
        disabled={disabled}
        onClick={onChange}
        readOnly
        style={{ zIndex: 20, cursor: "pointer" }}
      />
      {label && <Label>{label}</Label>}
    </span>
  );
};

Checkbox.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

Checkbox.defaultProps = {
  className: "",
  disabled: false,
  checked: false,
};

export default Checkbox;
