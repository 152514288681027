import React, {} from "react";
import { useParams } from "react-router-dom";
import useCultureQuizzInstance from "../../../hooks/useCultureQuizzInstance";
import QuestionsTypes from "./QuestionTypes";

import {Spinner, Button} from "../../../lib/components";


const headerDivStyle = {width: "20%"/*, fontSize: "0.95vw"*/, backgroundColor: "#7373af", color: "white", textAlign: "center", paddingTop: "0.6%", paddingBottom: "0.6%", borderRadius: 50, fontSize:"1.2vw"};

const QuizzPlay = () => {
  const params = useParams();

  const {
    loading,
    instance,
    quizz,
    question,
    sendAnswer } = useCultureQuizzInstance(params.uid);


  if (loading || !question || !instance || !quizz) return <Spinner />;

  return (
    <div style={{position:"relative", width: "100%", height: "calc(100vh - 110px) ", display: "flex", flexDirection: "column", backgroundColor:"#F2F2F2"}}>
      {QuestionsTypes.filter(i => i.slug === question.type).map(questionType => (<>
        {questionType.slug === question.type && instance.questionState === "answering" ? 
          <questionType.Answering/>
          : questionType.slug === question.type ?
            <questionType.DisplayAnswer question={question} />
            : null
        }
      </>))}

      <div style={{position: "absolute", zIndex: 1001, top: -95,left: "70px", right: "165px", height: "80px", display: "flex", justifyContent: "center"}}>
        {/*<Button onClick={abort}>Abort this quizz</Button>{" "}*/}
        {instance.questionState === "answering" ? 
          <div style={{position: "relative", width: "95%", height: "100%", display: "flex", justifyContent: "flex-start", alignItems:"center"}}>
            <span style={headerDivStyle}>Question : <b>{instance && instance.currentQuestion ? instance.currentQuestion  : 0} / {instance && instance.questionsCount ? instance.questionsCount : 0}</b></span>
          </div>
          :
          <div style={{position: "relative", width: "95%", height: "100%", display: "flex", justifyContent: "flex-start", alignItems:"center"}}>
            <span style={headerDivStyle}>Question : <b>{instance && instance.currentQuestion ? instance.currentQuestion  : 0} / {instance && instance.questionsCount ? instance.questionsCount : 0}</b></span>
          </div>
        }
      </div>
    </div>
  );
};

export default QuizzPlay;