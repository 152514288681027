import React, {createContext, useReducer, useEffect} from "react";
import PropTypes from "prop-types";
import firebase from "services/firebase";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const firestore = firebase.firestore;

const UIContext = createContext();

function UIReducer(state, action) {
  switch(action.type) {
  case "endLoad": 
    return {
      ...state,
      appLoaded: true
    };
  case "login":
    firestore().collection("users").doc(firebase.auth().currentUser.uid).update({lastLogin: firebase.firestore.FieldValue.serverTimestamp()});
    return {
      appLoaded: true,
      connected: true,
      user: {...action.user, auth : {...firebase.auth().currentUser}},
      establishment: action.establishment,
      groupement: action.groupement,
      refSenior: action.refSenior,
    };
  case "updateUser":
    return {
      ...state,
      user: {
        ...state.user,
        ...action.user,
      }
    };
  case "logout":
    firebase.auth().signOut();
    return {
      user: null,
      establishment: null,
      connected: false,
      appLoaded: true
    };
  case "takeControl":
    return ({
      ...state,
      user: action.user,
      establishment: action.establishment,
    });
  case "updateEstablishment":
    return ({
      ...state,
      establishment: action.establishment,
    });
  case "updateEstablishmentKey":
    return ({
      ...state,
      establishment: {
        ...state.establishment,
        [action.key]: action.value,
      },
    });
  case "updateGroupement":
    return ({
      ...state,
      groupement: action.groupement
    });
  case "updateGroupementKey":
    return ({
      ...state,
      groupement: {
        ...state.groupement,
        [action.key]: action.value,
      },
    });
  default:
    return {...state};
  }
}

const UIDefault = {
  user: null,
  establishment: null,
  groupement: null,
  connected: false,
  appLoaded: false
};

const Provider = ({children}) => {
  const [ui, dispatch] = useReducer(UIReducer, UIDefault);
  const history = useHistory();
  useEffect(() => {
    if(ui.user && ui.user.isDeleted) {
      dispatch({type: "setProperty", property: "connected", value: false});
      dispatch({type: "setProperty", property: "user", value: null});
      history.push("/login");
    }
  }, [ui.user]);
  


  return (
    <UIContext.Provider value={[ui, dispatch]}>
      {children}
    </UIContext.Provider>
  );
};

Provider.propTypes = {
  children : PropTypes.element,
};

export default UIContext;
export {Provider};