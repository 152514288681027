import firebase from "firebase";

const firestore = firebase.firestore;

export const sendNotifToMajor = ({ ui, data }, err) => {
    //data au format suivant : En adaptant suivant le type de notification à envoyer
    /*
        {
            type: "", // ["animation", "menu", "service", ...] 
            action: "", // ["add", "update", "delete"]
            data: {
                userId: "", // l'id de l'utilisateur qui a fait l'action 
                date: Date(),
                name: "", // repas, animation name, prestataire
                
                // specificités :
                category: "", // catégorie de l'animations ou du service
                
                homeDelivery: false, // portage sur les menus
                quantity: 3, //nombre de réservations sur les menus (invités)
                
                prestation: [""], // préstations choisies pour les services
                end: "", //heure de fin du service / animation
            }
        }
    */
    try {
        firestore()
            .collection("establishments")
            .doc(ui.user.establishment)
            .collection("notifications")
            .add({
                ...data,
                createdAt: new Date(),
                source: "major-connect-web",
                seenBy: []
            });
    } catch (e) {
        console.error(e);
    }
};