import React from "react";
import useUI from "hooks/ui.hook";

import Basic_LastActu from "./Basic/LastActu";
import Basic_NextActivities from "./Basic/NextActivities";
import Basic_TodayActivities from "./Basic/TodayActivities";
import Basic_TodayMenu from "./Basic/TodayMenu";

import Ovelia_TodayMenu from "./Specific/ovelia/TodayMenu";


export const LastActu = (props) => {
    return <Basic_LastActu {...props} />;
};

export const NextActivities = (props) => {
    return <Basic_NextActivities {...props} />;
};


export const TodayActivities = (props) => {
    return <Basic_TodayActivities {...props} />;
};


export const TodayMenu = (props) => {
    const [ui] = useUI();

    if (ui?.groupement?.specific === "ovelia") return <Ovelia_TodayMenu {...props} />;
    return <Basic_TodayMenu {...props} />;
};


