import React, { useState, useEffect, useMemo, useCallback } from "react";
import useUI from "hooks/ui.hook";
import {
    Button,
    Checkbox,
    FormGroup,
    Select
} from "lib/components";
import PropTypes from "prop-types";
import { useMenu } from "../../../router_context";

import styles from "../../../Basic/Assets/scss/dishCard.module.scss";

const DishCard = (props) => {
    const { selected, category, dishId, dishInfo, handleClick, disabled } = props;
    const [ctx] = useMenu();
    const textSize = useMemo(() => {

        const _reducer = ctx.modalInfos.repas === "Déjeuner" && dishInfo?.origin?.toLowerCase() === "menu du jour" ? 14 : 19;

        if(dishInfo.name.length > 20){
            const newSize =  1.1 - Math.round((dishInfo.name.length / _reducer)*100)/1000;  // Que le hasard soit, et le hasard fut.
            if(newSize < 0.55) return "0.55vw";
            return newSize+"vw";
        }else{
            return null;
        }
    },[dishInfo]);


    return (
        <div className={`${styles.container} ${selected ? styles.selected : null} ${disabled ? styles.disabled : null}`} onClick={() => !disabled ? handleClick(dishId) : null}>
            {ctx.modalInfos.repas === "Déjeuner" &&  dishInfo?.origin?.toLowerCase() === "menu du jour" ? <div className={styles.info}>
                {category} du jour
            </div>
            : null}
            <div style={{fontSize: textSize ?? "unset", paddingTop: ctx.modalInfos.repas === "Déjeuner" && dishInfo?.origin?.toLowerCase() === "menu du jour" ? "1vw" : null, fontWeight: selected ? "bold" : null}}>
                {dishInfo.name}
            </div>
        </div>
    );
};


DishCard.propTypes = {
    selected: PropTypes.bool,
    category: PropTypes.string,
    dishId: PropTypes.string,
    dishInfo: PropTypes.object,
    handleClick: PropTypes.func,
    disabled: PropTypes.bool,
};


export default DishCard;
