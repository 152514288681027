import React,{useEffect} from "react";
import PropTypes from "prop-types";
import {createContext, useReducer, useContext} from "react";
import firebase from "services/firebase";

import moment from "moment";
import useUI from "../../../hooks/ui.hook";
//import deepClone from "helpers/deepClone";
import { toast } from "react-toastify";
import useCategories from "../../../hooks/useCategories";
import useEvents from "../../../hooks/useEvents";

const firestore = firebase.firestore;
const functions = firebase.functions;


const Context = createContext();

const Default = {
  events: null,
  categories: null,
  selectedDate:  null,
  week : moment().startOf("week"),
  dateArray: [],
  dateDisplayArray: [],
  daysToDisplay : [1, 2, 3, 4, 5, 6, 7],
  loading : true,
  eventModal : false,
  eventSelected : null,
  returnToday: 0,
  banner: "",
};

function Reducer(state, action) {
  switch(action.type) {
  case "setProperty": return ({...state, [action.property]: action.value});
  default: return {...state};
  }
}

const Provider = ({children}) => {
  const [ui] = useUI();
  const [ctx, dispatch] = useReducer(Reducer, Default);
  const { events, loading: eventsLoading } = useEvents();
  const { categories, loading: categoriesLoading } = useCategories();

  //init
  useEffect(() => {
    (async () => {
      let res = await firestore()
      .collection("establishments")
      .doc(ui.user.establishment)
      .collection("blocks")
      .doc("planning")
      .get();
    let _planningDaysToDisplay = res.data().daysToDisplay;
    if (_planningDaysToDisplay && _planningDaysToDisplay.length > 0)
      dispatch({ type: "setProperty", property : "daysToDisplay", value: _planningDaysToDisplay});
    })()
  }, []);


  useEffect(() => {
    (async () => {
      if(events){
          dispatch({ type: "setProperty", property : "events", value: events});
      }
    })()
  }, [events]);
  
  useEffect(() => {
    (async () => {
      if(categories){
          dispatch({ type: "setProperty", property : "categories", value: categories});
      }
    })()
  }, [categories]);

  return (
    <Context.Provider value={[ctx, dispatch]}>
      {children}
    </Context.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.element,
};
const useAnimation = () => {
  const contexte = useContext(Context);

  if(!contexte) throw new Error("Le contexte n'est pas défini, il n'est probablement pas dans un provider !");

  return contexte;
};
export default useAnimation;
export {Provider, Context};