import { ArrowLeft, ArrowRight, Printer } from "react-feather";
import { Button, Spinner, Modal } from "../../../../../../lib/components";
import React, { useEffect, useState } from "react";

import Logo from "assets/images/jabiLogo/MAJOR_DETOUR.png";
import moment from "moment";
import styles from "assets/scss/pages/menu.module.scss";
import { useHistory } from "react-router-dom";
import { useSidebar } from "lib/context/dashboard/sidebar.context";
import useUi from "hooks/ui.hook";
import { sortRepas, isDayEmpty, hasBooked } from "../../../helpers/operations";
import { useMenu } from "../../../router_context";
import { ModalReservation, RepasComponent,  } from "../../../router_components";

const MenuVisualize = () => {
  const [ctx, dispatch] = useMenu();
  const [ui] = useUi();
  const history = useHistory();
  const [focused, setFocused] = useState(null);
  const [sidebar] = useSidebar();

  useEffect(() => {
    dispatch({
      type: "setProperty",
      property: "disabled",
      value: true,
    });
    dispatch({
      type: "setProperty",
      property: "selectedDate",
      value: moment().format("YYYY-MM-DD"),
    });
  }, []);

  const handleReservation = () => {
    dispatch({ type: "setProperty", property: "modalResaOpen", value: true });
  };

  const _export = () => {
    const weekData = ctx.daysToDisplay.reduce((acc, obj) => {
      const startOfWeek = moment(ctx.selectedDate).startOf("week");
      acc = [
        ...acc,
        startOfWeek
          .clone()
          .add(obj - 1, "days")
          .toDate(),
      ];
      return acc;
    }, []);
    history.push("/dashboard/menu/export", {
      week: weekData,
      menuState: ctx.data,
      logo: ui.establishment.profileImage,
    });
  };

  if (ctx.data === null) return <Spinner />;
  if (ctx.loading) return <Spinner />;

  return (
    <div className={styles.absoluteContainer}>
      <div className={styles.pageControl}>
        {/* <Button color="primary" onClick={_export} className={styles.button}>
              <Printer size={20} />
              <span>Imprimer</span>
          </Button> */}
      </div>
      <div className={styles.globalContainer}>
        <div className={styles.container}>
          <div className={styles.choseMenu} style={{ display: "flex", justifyContent: "center", marginBottom: "10px" }}>
            <Button
              onClick={handleReservation}
              size="xl"
            >
              Réserver
            </Button>
          </div>
          <div
            className={`${styles.container} ${sidebar ? styles.sidebarOpen : ""
              }`}
          >
            <div className={styles.repasContainer}>
              {isDayEmpty(
                ui.establishment.template,
                ctx.data[ctx.selectedDate]
              ) ? (
                <div className={styles.noRepas}>
                  Aucun Repas renseigné pour ce jour.
                </div>
              ) : (
                <>
                  {ui.establishment.template
                    ? sortRepas(
                      ui.establishment.template,
                      Object.keys(ui.establishment.template)
                    ).map((_repas, id) => (
                      id === 0 ?
                        <div
                          key={id}
                          className={styles.repasComponentContainer}
                        >
                          <RepasComponent repas={_repas} />
                        </div>
                        : null))
                    : null}
                </>
              )}
            </div>
          </div>
          <ModalReservation />
        </div>
      </div>
    </div>
  );
};

export default MenuVisualize;
