import React, {useEffect, useState} from "react";
import styles from "./truefalse.module.scss";
import { Circle, Triangle } from "react-feather";
import * as c from "../colors";
import { useParams } from "react-router-dom";
import useCultureQuizzInstance from "../../../../../hooks/useCultureQuizzInstance";
import useUI from "../../../../../hooks/ui.hook";
import QuestionResult from "../questionResult";

const Answering = () => {
  const [ui] = useUI();
  const params = useParams();
  const {
    instance,
    question  } = useCultureQuizzInstance(params.uid);

  const [answer, setAnswer] = useState(null);

  useEffect(() => {
    if (instance && instance.answers && instance.answers[instance.currentQuestion] && instance.answers[instance.currentQuestion][ui.user.uid]){
      setAnswer(instance.answers[instance.currentQuestion][ui.user.uid].answer);
    }
  }, [instance]);

  const hasAnswered = answer == null ? false : true;

  if(!question)return <></>;

  return (
    <div className={styles.displayAnswer}>
      <div className={styles.title}>
        <h3>{question.title}</h3>
      </div>
      <div className={styles.mediaDiv}>
        <QuestionResult question={question}/>
      </div>
      <div className={styles.answers}>
        <div className={`${styles.answer}`} 
           style={{
            opacity: 0.3,
            ...(answer === true ? { backgroundColor: c.COLOR_QUIZZ_SELECTED } : {}),
            ...(question.answer === true ? { backgroundColor: c.COLOR_PALETTE_RESULT[0], opacity: hasAnswered ? 1 : 0.3 } : {})
          }}
        >
          <Circle stroke={c.COLOR_PALETTE_1[0]} width={70} height={70} fill={c.COLOR_PALETTE_1[0]}/>
          <span>VRAI</span></div>
        <div className={`${styles.answer}`} 
          style={{
            opacity: 0.3,
            ...(answer === false ? { backgroundColor: c.COLOR_QUIZZ_SELECTED } : {}),
            ...(question.answer === false ? { backgroundColor: c.COLOR_PALETTE_RESULT[0], opacity: hasAnswered ? 1 : 0.3 } : {})
          }}
        >
          <Triangle stroke={ c.COLOR_PALETTE_1[1]} width={70} height={70} fill={ c.COLOR_PALETTE_1[1]}/>
          <span>FAUX</span></div>
      </div>
    </div>
  );
};


export default Answering;