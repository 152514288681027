import React, {createContext, useContext, useReducer} from "react";
import PropTypes from "prop-types";

const Context = createContext();

const Default = {
  step: "Register_Establishment",

  name: "",
  surname: "",
  mail: "",
  role:"",
  verified: "true",
  password: "",
  confirm: "",
  establishment: "",
  refSenior: "",
  refSeniorId: "",
  establishmentId: null,
};

function Reducer(state, action) {
  switch (action.type) {
    case "reset": return {...Default};
    case "navigate": return {...state, step: action.step};
    case "changeRole": return {...state, role: action.role};
    case "changeName": return {...state, name: action.name};
    case "changeSurname": return {...state, surname: action.surname};
    case "changeMail": return {...state, mail: action.mail};
    case "changePassword": return {...state, password: action.password};
    case "changeConfirm": return {...state, confirm: action.confirm};
    case "changeEstablishment": return {...state, establishment: action.establishment};
    case "changeRefSenior": return {...state, refSenior: action.refSenior};
    case "changeEstablishmentId": return {...state, establishmentId: action.establishmentId};
    case "changeRefSeniorId": return {...state, refSeniorId: action.refSeniorId};
    default: return {...state};
  }
}

const Provider = (props) => {
  const {children} = props;
  const [ctx, dispatch] = useReducer(Reducer, Default);

  return (
    <Context.Provider value={[ctx, dispatch]}>
      {children}
    </Context.Provider>
  );
};
Provider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useCtx = () => useContext(Context);

export {Provider};
export default useCtx;