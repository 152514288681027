import {useContext, useEffect} from "react";
import UIContext from "../contexts/ui.context";
import firebase from "services/firebase";
import sleep from "../lib/helpers/asyncWait";

const auth = firebase.auth();
const firestore = firebase.firestore();

const useAuth = () => {
  const [ui, UIReducer] = useContext(UIContext);

  useEffect(() => {
    const subscribe = auth.onAuthStateChanged(async (_user) => {
      if (_user) {
        //get user data
        let userDoc = await firestore.collection("users").doc(firebase.auth().currentUser.uid).get();
        while (!userDoc.data()) {//potentially wait custom data generated by cloud function onUserCreate
          await sleep(2000);
          userDoc = await firestore.collection("users").doc(firebase.auth().currentUser.uid).get();
        }
        const userData = {...userDoc.data(), uid:userDoc.id };


        let establishmentData = null;

        if (userData.establishment) {
          //get establishment data
          let establishmentDoc = await firestore.collection("establishments").doc(userData.establishment).get();
          while (!establishmentDoc.data()) {
            await sleep(2000);
            establishmentDoc = await firestore.collection("establishments").doc(userData.establishment).get();
          }
          establishmentData = establishmentDoc.data();
          establishmentData.uid = userData.establishment;
          
          let resGroupement = await firestore.collection("groupements").doc(establishmentData.groupement).get();
          resGroupement = ({uid: resGroupement.id, ...resGroupement.data()});
        }
        
        let groupement = null;
        if(establishmentData){
          let groupementDoc = await firestore.collection("groupements").doc(establishmentData.groupement).get();
          groupement = {uid: establishmentData.groupement, ...groupementDoc.data()};
        }
        if(userData.isDeleted) {
          UIReducer({type: "endLoad"});
        } else {
          UIReducer({type: "login", user: userData, establishment: establishmentData, groupement : groupement});
        }
      } else {
        UIReducer({type: "endLoad"});
      }
    });
    return subscribe;
  }, []);

  //complete user data with groupement
  useEffect(() => {
    (async () => {
      if (!ui?.user?.establishment) return;

    })();
  }, [ui?.user?.establishment]);
};

export default useAuth;