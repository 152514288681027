import React, {useEffect, useState} from "react";
import {Card, Form, FormGroup, Input, Button} from "../lib/components";
import firebase from "services/firebase";
import styles from "../lib/assets/scss/pages/login.module.scss";
import {toast} from "react-toastify";
import {useHistory} from "react-router-dom";
import Loader from "../lib/pages/Loader";

const auth = firebase.auth;
const firestore = firebase.firestore();

const LoginPage = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");
  const [overlayLoading, setOverlayLoading] = useState(false);

  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (!token) return;
    setOverlayLoading(true);
    try {
      auth().signInWithCustomToken(token);
    } catch (e) {
      console.error(e);
    }
  }, []);

  const onConnect = () => {
    setLoading(true);
    firebase.auth().signInWithEmailAndPassword(mail, password)
      .then(async () => {
        console.log("connected");
        let userData = (await firestore.collection("users").doc(firebase.auth().currentUser.uid).get()).data();
        setLoading(false);

        if(userData.isDeleted) {
          toast.error("Compte suspendu. Rapprochez-vous de votre établissement pour plus d'informations.");
        } else {
          toast.success(`Content de vous revoir ${userData.surname}`);
        }
      })
      .catch((e) => {
        toast.error("Email/Mot de passe incorrect");
        setLoading(false);
        console.error(e);
      });
  };

  if (overlayLoading) return (
    <Loader />
  );
  return (
    <div className={styles.loginPage}>
      <Card className={styles.card} color="white">
        <h1>Se connecter</h1>
        <Form>
          <FormGroup>
            <Input label={"Email"} value={mail} onChange={e => setMail(e.target.value)} autoComplete="mail"
              onEnterPressed={onConnect} />
          </FormGroup>
          <FormGroup>
            <Input autoComplete="password" label={"Mot de passe"} type='password' value={password} onChange={e => setPassword(e.target.value)}
              onEnterPressed={onConnect} />
          </FormGroup>
          <p onClick={() => history.push("forget")} style={{textAlign: "center", marginBottom: 10, marginTop: -5, textDecoration: "underline", color: "#51075e", cursor: "pointer"}}>Mot de passe oublié</p>
          <FormGroup style={{textAlign: "center"}}>
            <Button onClick={onConnect} wd="300px" mg={{all: "0"}} loading={loading}>{"Se connecter"}</Button>
          </FormGroup>
        </Form>
      </Card>
    </div>
  );
};

export default LoginPage;