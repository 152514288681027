import * as Sentry from "@sentry/react";

const MODE = 
["127.0.0.1","localhost", "major-dev-fdd82.web.app", "major-dev-fdd82.firebaseapp.com"].includes(window.location.hostname) ? "DEV" : 
  ["major-preprod-4ae67.web.app", "major-preprod-4ae67.firebaseapp.com", "preprod.app.jabi.fr"].includes(window.location.hostname) ? "PREPROD" 
    : "PROD";

if (MODE === "PROD") {
  Sentry.init({
    dsn: "https://49254329fc1cfd375e2e57f1fc1792db@o4507214226259968.ingest.de.sentry.io/4507215382970448",
    integrations: [],
    tracesSampleRate: 1.0,
  });
}

export default MODE;