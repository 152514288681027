import { Button, Spinner } from "../../../lib/components";
import React, { useState } from "react";

import NewsCard from "./components/NewsCard";
import { PlusCircle } from "react-feather";
import moment from "moment";
import styles from "../../../assets/scss/pages/news.module.scss";
import { useHistory } from "react-router-dom";
import useNews from "../../../hooks/useNews";

const News = () => {
  const { data, loading } = useNews();
  const history = useHistory();

  if (loading) return <Spinner />;
  return (
    <div className={styles.absContainer}>
      <div className={styles.container}>
        <span className={styles.titleActus}>
          {" "}
          Retrouvez toutes les actualités de votre résidence :{" "}
        </span>
        <div className={styles.body}>
          {data
            .sort(
              (a, b) =>
                moment(b.date.toDate()).format("YYYYMMDD") -
                moment(a.date.toDate()).format("YYYYMMDD")
            )
            .map((item, indx) => (
              <div key={indx} className={styles.cardContainer}>
                <NewsCard
                  item={item}
                  onClick={() => history.push(`/dashboard/news/${item.uid}`)}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default News;
