
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { pdfjs, Document, Page } from "react-pdf";
import HTMLFlipBook from "react-pageflip";
import styles from "../../../assets/scss/pages/Gazette/FlipBook.module.scss";
import { ChevronLeft, ChevronRight } from "react-feather";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function FlipBook(props) {
  const book = useRef();
  const ref = useRef(null);
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurentPage] = useState(0);
  const [height, setHeight] = useState(0);


  useEffect(() => {
    if(ref.current != null){
      setHeight(ref.current.clientHeight);
    }
  },[ref.current]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const pageFliped = (e) => {
    setCurentPage(e.data);
  };

  const goNextPage = () => {
    if(numPages > 0){
      book.current.pageFlip().flipNext();
    }
  };

  const goPreviousPage = () => {
    if(numPages > 0){
      book.current.pageFlip().flipPrev();
    }
  };

  const { pdf } = props;
  if(!pdf)return <></>;

  const heightA4 = (height * 0.9);
  const widthA4 = heightA4 / 1.41553398;

  return (
    <div className={styles.container}>
      
      <div style={{display: "none"}}>
        <Document
          file={pdf}
          onLoadSuccess={onDocumentLoadSuccess}
        ></Document>
      </div>
     
      <div className={styles.flipContainer} ref={ref}>
        <div className={styles.flipButton} onClick={goPreviousPage}><ChevronLeft color={currentPage === 0 ? "#a6d1ff" : "#007aff"} size={60}/></div>
        {widthA4 >=0 && heightA4 >= 0 ? 
            <HTMLFlipBook width={widthA4} height={heightA4} showCover={true} maxShadowOpacity={0.5} usePortrait={false}
            onFlip={pageFliped}
            ref={book}
            >
              {numPages > 0 ? [...Array(numPages).keys()].map((id) => (
                <div className={styles.page} key={id+1}>
                    <div className={`${styles.pageShadow} ${id === 1 ? styles.cover : id%2 === 0 ? styles.pageLeft : styles.pageRight} `}></div>
                    <Document 
                      file={pdf}
                      >
                        <Page  pageNumber={id+1} width={widthA4-1}/>
                    </Document>
                </div>
              )) : null}
            </HTMLFlipBook>
          : null}
          <div className={styles.flipButton} onClick={goNextPage}><ChevronRight color={currentPage+1 === numPages || currentPage+2 === numPages ? "#a6d1ff" : "#007aff"} size={60}/></div>
      </div>
      <div>page {currentPage+1}/{numPages}</div>

        
    </div>
  );
}

FlipBook.propTypes = {
    pdf: PropTypes.any,
  };