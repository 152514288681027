import React, {useEffect, useState} from "react";
import {Card, Form, FormGroup, Input, Button} from "lib/components";

import useCtx from "./context";
import { ArrowLeft } from "react-feather";

const Role = () => {
  const [ctx, dispatch] = useCtx();

  const addRole = async role => {
    dispatch({type: "changeRole", role: role});
    dispatch({type: "navigate", step: "Register_Name"});
  };

  return (
    <div className="flex justify-center items-center w-full" style={{height: "calc(100vh - 90px)"}}>
      <Card color="white" className="relative" >
        <ArrowLeft className="cursor-pointer" onClick={() => dispatch({type: "navigate", step: "Register_Establishment"})} />
        <h3 className="text-center">Inscription 2/4</h3>
        <p>Choisissez le parcours qui vous correspond</p>
        <div className="flex justify-center p-4" style={{gap: 10}}>
          <div onClick={() => addRole("family")} className="p-4 rounded cursor-pointer" style={{border: "3px solid #a400a8"}}>
            <h3 className="m-0" style={{color: "#5a005c"}}>Famille</h3>
          </div>
          <div onClick={() => addRole("senior")} className="p-4 rounded cursor-pointer" style={{border: "3px solid #a400a8"}}>
            <h3 className="m-0" style={{color: "#5a005c"}}>Senior</h3>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Role;
