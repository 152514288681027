import React, {useState} from "react";
import firebase from "services/firebase";
import {Card, Form, FormGroup, Input, Button} from "../lib/components";
import {toast} from "react-toastify";
import { useHistory } from "react-router-dom";

import styles from "../lib/assets/scss/pages/login.module.scss";

const auth = firebase.auth;

const ForgetPassword=  () => {
  const history = useHistory();
  const [mail, setMail] = useState("");
  const [loading, setLoading] = useState(false);

  const _onSubmit = async (e) => {
    try {
      if (e) e.preventDefault();

      setLoading(true);
      await auth().sendPasswordResetEmail(mail);
      toast.success("Un mail de réinitialisation vient de vous être envoyé");
      setMail("");
      history.push("login");
    } catch (e) {
      console.error(e.message);
      toast.error(e.message);
    }
    setLoading(false);
  };

  return (
    <div className={styles.loginPage}>
      <Card className={styles.card} color="white">
        <h1>Mot de passe oublié</h1>
        <Form>
          <FormGroup>
            <Input label="Email" value={mail} onChange={e => setMail(e.target.value)} autoComplete="mail" onEnterPressed={_onSubmit}/>
          </FormGroup>
          <FormGroup>
            <Button onClick={_onSubmit} loading={loading}>
              Réinitialiser mon mot de passe
            </Button>
          </FormGroup>
          <p onClick={() => history.push("login")} style={{textAlign: "center", marginBottom: 0, marginTop: -5, textDecoration: "underline", color: "#51075e", cursor: "pointer"}}>Me connecter</p>
        </Form>
      </Card>
    </div>
  );
};

export default ForgetPassword;