import React, { useState, useEffect } from "react";
import useCategories from "../../../hooks/useCategories";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  ColorPicker,
  FormGroup,
  Spinner,
} from "../../../lib/components";
import { SmileyPicker } from "lib/components/Form";
import styles from "../../../assets/scss/components/homeWidgets/animation_categoriesCrud.module.scss";

import { Plus } from "react-feather";

const CategoriesCrud = () => {
  const { categories, categoryCreate, categoryUpdate, categoryDelete } = useCategories();

  return (
    <div className={styles.container}>
      <div style={{ display: "flex", flexWrap: "wrap", gap: 5 }}>
        {categories.map((i) => (
          <span
            key={i.uid}
            className={styles.button}
            style={{ backgroundColor: i.color }}
          >
            {i.title}
            {i.smiley ? <img src={i.smiley} width="22px" style={{ marginLeft: 10 }}></img> : null}
          </span>
        ))}
      </div>
    </div>
  );
};

export default CategoriesCrud;
