import { Button } from "../../../../lib/components";
import PropTypes from "prop-types";
import React from "react";
import moment from "moment";
import noImg from "./noImg.jpeg";
import styles from "../../../../assets/scss/pages/news.module.scss";
import { useHistory } from "react-router-dom";
import { InfoCard, InfoCardHeader, InfoCardBody } from "../../../../lib/components/InfoCard";

const NewsCard = (props) => {
  const { item, onClick } = props;
  const history = useHistory();

  return (
    <InfoCard height={"large"}>
      <InfoCardHeader className={styles.cardHeaderColor} title={item.title} tag={item.type ?? "local"}>
      </InfoCardHeader>
      <InfoCardBody image={item.image ?? noImg}>
        <div className={styles.content}>
          <span className={styles.description}>
            {item.description}
          </span>
          <div className={styles.actuActions}>
            <span className={styles.actuDate}>Publié le {moment(item.date.toDate()).format("l")}</span>
            <Button color="primary" onClick={()=> history.push("/dashboard/news/"+item.uid)} className={styles.button}>Consulter</Button>
          </div>
        </div>
      </InfoCardBody>
    </InfoCard>            
  );
};

NewsCard.propTypes = {
  onClick: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
};

export default NewsCard;
