import {useState, useEffect} from "react";
import useUI from "./ui.hook";
import firebase from "services/firebase";

const firestore = firebase.firestore;

const useEvents = (activeFetch = true) => {
  const [ui] = useUI();
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState(null);
  const [eventCollectionRef, setEventCollectionRef] = useState(null);

  useEffect(() => {
    (async () => {
      let blocks = [];
      let estBlocksQuery = await firestore().collection("establishments")
        .doc(ui.user.establishment)
        .collection("blocks")
        .get();
      estBlocksQuery.forEach(doc => blocks.push({uid: doc.id, ...doc.data()}));
      let planningBlock = blocks.filter(i => i.blockType === "plan");
      
      let eventRef;
      if (!planningBlock.length) {
        await firestore().collection("establishments")
          .doc(ui.user.establishment)
          .collection("blocks")
          .doc("planning")
          .set({blockType: "plan"});
        eventRef = firestore().collection("establishments")
          .doc(ui.user.establishment)
          .collection("blocks")
          .doc("planning")
          .collection("events");
      } else {
        eventRef = firestore().collection("establishments")
          .doc(ui.user.establishment)
          .collection("blocks")
          .doc(planningBlock[0].uid)
          .collection("events");
      }
      setEventCollectionRef(eventRef);

      if (activeFetch) {
        eventRef.orderBy("start", "asc").onSnapshot(snap => {
          const data = snap.docs.map(doc => ({
            uid: doc.id,
            ...doc.data()
          }));
          setEvents(data);
          setLoading(false);
        });
      } else {
        setLoading(false);
      }
    })();
  }, []);

  const eventCreate = async (event) => {
    let title = event.title;
    let place = event.place;
    let description = event.description;
    let eventType = event.type;
    let start = new Date(event.date.getTime());
    let end = new Date(event.date.getTime());
    let file = event.file;
    let image = event.image ? (typeof(event.image) === "string" ? event.image : event.image.urls.small) : null;
    let maxParticipants = event.maxParticipants ? event.maxParticipants : 0;

    start.setHours(event.startTime.value.split("h")[0]);
    start.setMinutes(event.startTime.value.split("h")[1]);
    start.setSeconds(0);
    end.setHours(event.endTime.value.split("h")[0]);
    end.setMinutes(event.endTime.value.split("h")[1]);
    end.setSeconds(0);
  
    await eventCollectionRef.add({
      title,
      place,
      description,
      eventType,
      start,
      end,
      file,
      image,
      categoryId: event.categoryId,
      maxParticipants
    });
  };

  const eventDelete = async (eventId) => {
    await eventCollectionRef.doc(eventId).delete();
  };

  const eventUpdate = async (eventId, event) => {
    let title = event.title;
    let place = event.place;
    let description = event.description;
    let eventType = event.type;
    let start = new Date(event.date.getTime());
    let end = new Date(event.date.getTime());
    let file = event.file;
    let image = event.image ? (typeof(event.image) === "string" ? event.image : event.image.urls.small) : null;
    let maxParticipants = event.maxParticipants ? event.maxParticipants : 0;

    start.setHours(event.startTime.value.split("h")[0]);
    start.setMinutes(event.startTime.value.split("h")[1]);
    start.setSeconds(0);
    end.setHours(event.endTime.value.split("h")[0]);
    end.setMinutes(event.endTime.value.split("h")[1]);
    end.setSeconds(0);

    await eventCollectionRef.doc(eventId).update({
      title,
      place,
      description,
      eventType,
      start,
      end,
      file,
      image,
      categoryId: event.categoryId,
      maxParticipants
    });
  };

  return ({
    loading,
    events,
    eventCreate,
    eventDelete,
    eventUpdate
  });
};

export default useEvents;