import {useState, useEffect} from "react";
import firebase from "services/firebase";
import useUI from "./ui.hook";

const firestore = firebase.firestore;

const useNews = () => {
  const [ui] = useUI();
  const [newsEtab, setNewsEtab] = useState([]);
  const [newsGrp, setNewsGrp] = useState([]);

  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const subscribe = firestore().collection("establishments").doc(ui.user.establishment)
      .collection("blocks").doc("actus")
      .collection("actus").onSnapshot(snap => {
        let _data = [];
        snap.forEach(doc => _data.push({uid: doc.id, ...doc.data()}));
        setNewsEtab(_data);
      }, (e) => {
        setError(e);
      });
    return subscribe;
  }, []);

  useEffect(() => {
    if(ui.groupement){
      let scope = ["national"];
      if(ui.establishment.region)scope.push(ui.establishment.region);
      const subscribe = firestore().collection("groupements").doc(ui.groupement.uid)
      .collection("actus").where("type","in", scope).onSnapshot(snap => {
        let _data = [];
        snap.forEach(doc => _data.push({uid: doc.id, ...doc.data()}));
        setNewsGrp(_data);
      }, (e) => {
        setError(e);
      });

      return subscribe;
    }
    
  }, []);

  useEffect(() => {
      setData(newsEtab.concat(newsGrp));
      setLoading(false);
  }, [newsEtab, newsGrp]);

  return ({data, error, loading});
};

export default useNews;