import React from "react";

import Basic_useMenu, {Provider as Basic_MenuProvider} from "./Basic/Context/context";


const MenuProvider = (props) => {
    return <Basic_MenuProvider {...props} />;
};

const useMenu = () => {
    return Basic_useMenu();
};

export { useMenu, MenuProvider };