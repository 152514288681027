import React, {useState} from "react";
import { useHistory,useParams } from "react-router-dom";
import { useFirebaseDocument } from "lib/hooks/firebase";
import useUI from "hooks/ui.hook";
import firebase from "services/firebase";
import { Button } from "../../../lib/components";
import FlipBook from "./FlipBook";

const firestore = firebase.firestore;

const GazetteView = () => {
const {uid} = useParams();

  const history = useHistory();
  const [ui] = useUI();
  const [gazettesRef] = useState(firestore().collection("establishments").doc(ui.user.establishment).collection("gazettes").doc(uid));
  const {data} = useFirebaseDocument(gazettesRef, []);

  return (
    <>
      <div style={{width: "100%", height: "85vh", overflow: "hidden"}}>
        <div style={{position: "absolute"}}>
          <Button color="primary" size="sm" onClick={() => history.goBack()}>Retour</Button>
        </div>
        <div style={{width: "100%", height: "85vh"}}>
          <FlipBook pdf={data?.publication}/>
          {!data?.publication ? <div>Aucune Gazette disponible.</div>: null}
        </div>
      </div>
    </>
  );
};

export default GazetteView;