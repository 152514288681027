import React from "react";
import PropTypes from "prop-types";
import useAuth from "./hooks/auth.hook";
import useUI from "./hooks/ui.hook";
import useSentry from "lib/hooks/useSentry";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import {
  PublicLayout
} from "./lib/layouts";

import {
  ErrorPage,
  LoaderPage
} from "./lib/pages";

import PublicHeader from "./config/PublicHeader";

import LoginPage from "./pages/Login";
import RegisterPage from "./pages/Register";

import ForgetPassword from "./pages/ForgetPassword";
import ForgetAction from "./pages/ForgetAction";

import Major from "./pages/Major";

import WrongApp from "./pages/WrongApp";

const AppRouter = () => {
  useSentry();
  const [ui] = useUI();
  //get token in url
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");

  const RouterConditions = ({match}) => {
    if(ui.connected){
      if (["senior", "family", "owner", "admin", "employee"].includes(ui.user.role)) {
        return <Major match={match} />;
      } else {
        return <WrongApp />;
      }
    } else {
      return <Redirect to={token ? `/login?token=${token}` : "/login"} />;
    }
  };

  RouterConditions.propTypes = {
    match : PropTypes.object.isRequired,
  };
  
  return (
    <>
      <Switch>
        {/* error routes */}
        <Route path="/401"><PublicLayout><ErrorPage code="401" /></PublicLayout></Route>
        <Route path="/403"><PublicLayout><ErrorPage code="403" /></PublicLayout></Route>
        <Route path="/404"><PublicLayout><ErrorPage code="404" /></PublicLayout></Route>
        <Route path="/500"><PublicLayout><ErrorPage code="500" /></PublicLayout></Route>

        {/* Dashboard */}
        <Route path="/dashboard">
          {({match}) => (
            <>
              <RouterConditions match={match}/>
            </>
          )}
        </Route>

        {/* Public */}
        <Route path="/">
          <PublicLayout header={<PublicHeader />}>
            <Switch>
              {ui.connected && <Route path="*"><Redirect to='/dashboard' /></Route>}
              <Route exact path="/login"><LoginPage /></Route>
              <Route exact path="/register"><RegisterPage /></Route>
              <Route exact path="/forget"><ForgetPassword /></Route>
              <Route exact path="/forget/action"><ForgetAction /></Route>
              <Route path="*"><Redirect to={token ? `/login?token=${token}` : "/login"} /></Route>
            </Switch>
          </PublicLayout>
        </Route>
      </Switch>
    </>
  );
};

const RouterHOC = () => {
  useAuth();
  const [ui] = useUI();

  return (
    <>
      {ui.appLoaded ?
        <Router>
          <AppRouter />
        </Router>
        :
        <LoaderPage show />
      }
    </>
  );
};


export default RouterHOC;