import React, {useState, useEffect, useMemo} from "react";
import {Input, Button} from "lib/components";
import {Chrono} from "react-chrono";
import { Trash, Edit, Save, X } from "react-feather";
import PropTypes from "prop-types";

import useCtx from "../ctx";
import useUI from "hooks/ui.hook";
import { toast } from "react-toastify";
import moment from "moment";
import { useCallback } from "react";

const Card = (props) => {
  const {data} = props;
  const {removeBook, updateBook} = useCtx();
  const [ui] = useUI();

  const [isEditing, setIsEditing] = useState(false);
  const [contentBuffer, setContentBuffer] = useState(data.content);
  
  const _onStartEditing = useCallback(() => {setIsEditing(true); setContentBuffer(data.content);}, [data.content]);

  return (
    <div style={{width: "100%", height: "160px", display: "flex", flexDirection: "column"}}>
      <div style={{flex: 1, overflowY: "auto"}}>
        {isEditing ? 
          <Input type="textarea" value={contentBuffer} onChange={e => setContentBuffer(e.target.value)} style={{height: 120}} />
        :
          <p style={{textAlign: "left"}}>{data.content}</p>
        }
      </div>
      {ui.user.uid === data.createdByUid ? 
        <div style={{display: "flex", justifyContent: "flex-end", marginTop: 10, gap: 5}}>
          {isEditing ? 
          <>
            <X color="#888" style={{cursor: "pointer"}} onClick={() => setIsEditing(false)} />
            <Save color="#4a0956" style={{cursor: "pointer"}} onClick={() => {updateBook(data.uid, contentBuffer); setIsEditing(false);}} />
          </>
          :
          <>
            <Edit color="#4a0956" style={{cursor: "pointer"}} onClick={_onStartEditing} />
            <Trash color="red" style={{cursor: "pointer"}} onClick={() => removeBook(data.uid)} />
          </>}
        </div>
      : null}
    </div>
  );
};
Card.propTypes = {
  data: PropTypes.object.isRequired,
};

const Book = () => {
  const {state, addBook} = useCtx();
  const {book} = state;
  const [content, setContent] = useState("");

  const bookSort = useMemo(() => (book ?? []).sort((a, b) => b.createdAt - a.createdAt), [book]);

  const _add = async () => {
    if (!content) return;
    try {
      await addBook(content);
      setContent("");
      toast.success("Message ajouté");
    } catch (e) {
      console.log("e", e);
      toast.error("Une erreur est survenue");
    }
  };

  return (
    <>
      <div>
        <Input type="textarea" placeholder="..." value={content} onChange={e => setContent(e.target.value)} />
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: 10}}>
          <Button onClick={_add}>Envoyer</Button>
        </div>
      </div>
      <div>
        <Chrono
          allowDynamicUpdate
          mode="VERTICAL_ALTERNATING"
          hideControls
          items={bookSort.map(i => ({
            cardSubtitle: `${i.createdByName} - ${moment(i.createdAt).format("DD/MM/YYYY HH:mm")}`,
          }))}
        >
          {bookSort.map(i => <Card key={i.uid} data={i} />)}
        </Chrono>
      </div>
    </>
  );
};

export default Book;