import React, {useState} from "react";
import { Edit } from "react-feather";
import {Container, Row, Col,
  Table, Icon, Tag, Button,
  Card, CardHeader, CardBody} from "../../../lib/components";
import firebase from "services/firebase";
import useUI from "../../../hooks/ui.hook";
import {Plus} from "react-feather";
import {useFirebaseCollection} from "lib/hooks/firebase";
import roles from "enums/roles";
import { useHistory } from "react-router-dom";

import styles from "../../../assets/scss/pages/userList.module.scss";

const firestore = firebase.firestore;

const UserList = () => {
  const history = useHistory();
  const [ui] = useUI();
  const [usersRef] = useState(firestore().collection("users").where("establishment", "==", ui.user.establishment));
  const {data: users} = useFirebaseCollection(usersRef);
  const [userFilter, setUserFilter] = useState([]);
  
  const handleFilter = (value) => {
    let _userFilter = [...userFilter];

    if(value == "all"){
      _userFilter = [];
    }else{
      if(_userFilter.indexOf(value) == -1){
        _userFilter.push(value);
      }else{
        _userFilter.splice(_userFilter.indexOf(value), 1);
      }
    }
    setUserFilter(_userFilter);
  };
  const RenderFilter = () => {
    let retour = [];
    retour.push(<div style={{backgroundColor: "#51075e", color: "#FFFFFF", padding: 6, borderRadius: 10, cursor: "pointer", opacity: userFilter.length > 0 ? 0.35 : 1}} onClick={() => handleFilter("all")}>Tous</div>);
    roles.forEach(element => {
      if(element.value != "superadmin" && element.value != "groupementAdmin"){
        retour.push(<div style={{backgroundColor: element.color, color: "#FFFFFF", padding: 6, borderRadius: 10, cursor: "pointer", opacity: userFilter.indexOf(element.value) == -1 ? 0.35 : 1}} onClick={() => handleFilter(element.value)}>{element.label}</div>);
      }
    });

    return <div style={{display:"flex", flex: 1, flexWrap: "wrap",flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 10}}>
      {retour}
    </div>;
  };

  const filterUsers = (user) => {
    if(  userFilter.length == 0)
      return true;
    else
      return userFilter.indexOf(user.role) != -1 ? true : false;
  };

  return (
    <>
      <Container>
        <RenderFilter/>
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeader style={{display: "flex", justifyContent: "space-between"}}>
                <h5>Liste des utilisateurs</h5>
                <Button color="primary" ><Plus /></Button>
              </CardHeader>
              <CardBody>
                <Table className={styles.table} loading={users === null}>
                  <thead>
                    <tr>
                      <th>Nom</th>
                      <th>Prénom</th>
                      <th>Role</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.filter(filterUsers).map(user => (
                      <tr key={user.uid}>
                        <td>{user.name}</td>
                        <td>{user.surname}</td>
                        <td>
                          <Tag style={{backgroundColor: (roles.find(r => r.value === user.role) ?? {}).color}}>
                            {(roles.find(r => r.value === user.role) ?? {}).label ?? user.role}
                          </Tag>
                        </td>
                        <td className={styles.actions}>
                          {user.uid !== ui.user.uid ? 
                            ui.user.groupementAdmin || ui.user.superadmin ? 
                              <>
                                {/*<Icon icon={Trash} color='danger' onClick={() => onDelete(user.uid)}/> &nbsp;*/}
                                <Icon icon={Edit} color='info' className={styles.icon} onClick={() => history.push(`/dashboard/users/${user.uid}`)} />
                              </>
                              : 
                              user.role != "owner" ?
                                <>
                                  {/*<Icon icon={Trash} color='danger' onClick={() => onDelete(user.uid)} /> &nbsp;*/}
                                  <Icon icon={Edit} color='info' className={styles.icon} onClick={() => history.push(`/dashboard/users/${user.uid}`)} />
                                </>
                              : null
                          : null}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UserList;