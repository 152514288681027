import React from "react";
import PropTypes from "prop-types";
import {Page, Text, View, Document, StyleSheet} from "@react-pdf/renderer";
import moment from "moment";

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    paddingBottom: 5,
  },
  monthHeader: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  monthTitle: {
    textAlign: "center",
    fontSize: 22,
    fontWeight: "700"
  },
  dayHeaderContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    width: "100%",
    paddingTop: 20,
    paddingLeft: 30,
    paddingRight: 30
  },
  weekDay: {
    flex: 1,
    border: "1px solid red",
    height: 40,
    textAlign: "center",
    paddingTop: 7,
    fontWeight: "700"
  },
  weekContainer: {
    flex: 10,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    paddingLeft: 30,
    paddingRight: 30
  },
  week: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  day: {
    border: "1px solid red",
    flex: 1,
    padding: 5,
    display: "flex",
    flexDirection: "column",
    height: 80,
  },
  dayTitle: {
    color: "black",
    fontSize: 18,
  },
  eventHour: {
    fontSize: 9,
    flex: 2,
    color: "#D00"
  },
  eventTitle: {
    marginLeft: 5,
    fontSize: 9,
    flex: 6
  },
});

const WeekPlanningPdf = (props) => {
  const {
    monday,
    events
  } = props;


  const _computeDays = () => {
    let firstDayOfMonth = new Date(monday);
    let firstDayOfExport = new Date(monday);
    let weeks = [];
    
    firstDayOfMonth.setDate(1);
    firstDayOfExport.setDate(firstDayOfMonth.getDate() - firstDayOfMonth.getDay() + 1);

    do {
      weeks.push([]);
      for (let i = 0; i < 7; i++) {
        weeks[weeks.length - 1].push(new Date(firstDayOfExport));
        firstDayOfExport.setDate(firstDayOfExport.getDate() + 1);
      }
    } while (weeks[weeks.length - 1][weeks[weeks.length - 1].length - 1].getMonth() === firstDayOfMonth.getMonth());
    return (weeks);
  };

  const _getEventByDay = (day) => {
    let start = new Date(day);
    let end = new Date(day);

    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(1);
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);

    return (events.filter(event => event.start > start && event.end < end));
  };

  const _monthToString = (date) => {
    return moment(date).format("MMMM YYYY");
  };
  const _dateToString = (date) => {
    return moment(date).format("Do");
  };
  const _hourToString = (date) => {
    return moment(date).format("HH:mm");
  };

  return (
    <Document>
      <Page size="A4" orientation="landscape">
        <View style={styles.container}>
          <View style={styles.monthHeader}>
            <Text style={styles.monthTitle}>{_monthToString(monday)}</Text>
          </View>
          <View style={styles.dayHeaderContainer}>
            <Text style={styles.weekDay}>Lundi</Text>
            <Text style={styles.weekDay}>Mardi</Text>
            <Text style={styles.weekDay}>Mercredi</Text>
            <Text style={styles.weekDay}>Jeudi</Text>
            <Text style={styles.weekDay}>Vendredi</Text>
            <Text style={styles.weekDay}>Samedi</Text>
            <Text style={styles.weekDay}>Dimanche</Text>
          </View>
          <View style={styles.weekContainer}>
            {_computeDays().map((w, index) => (
              <View style={styles.week} key={index}>
                {w.map((d, index2) => (
                  <View style={styles.day} key={index + index2}>
                    <Text style={styles.dayTitle}>{_dateToString(d)}</Text>
                    {_getEventByDay(d).map((event, index3) => (
                      <View key={index + index2 + index3} style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", marginTop: 4, marginBottom: 4}}>
                        <Text style={styles.eventHour}>{_hourToString(event.start)}</Text>
                        <Text style={styles.eventTitle}>{event.title}</Text>
                      </View>
                    ))}
                  </View>
                ))}
              </View>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};

WeekPlanningPdf.propTypes = {
  monday: PropTypes.bool.isRequired,
  events: PropTypes.array.isRequired,
};

export default WeekPlanningPdf;