import React, {useState, useEffect} from "react";
import useUI from "hooks/ui.hook";
import { Spinner } from "lib/components";
import styles from "assets/scss/components/homeWidgets/todayMenu.module.scss";
import moment from "moment";
import { MenuProvider, useMenu } from "../../Menu/router_context";
import { MenuCard } from "../../Menu/router_components";
import { sortRepas, isRepasEmpty, sortMenus, isMenuEmpty } from "../../Menu/helpers/operations";
import { useInterval, useTimeoutWhen } from "rooks";
import {Card} from "lib/components";

const TodayMenuComponent = () => {
  const [ui] = useUI();
  const [ctx, dispatch] = useMenu();
  const [loading, setLoading] = useState(true);
  const [repas, setRepas] = useState([]);
  const [intervalValue, setIntervalValue] = useState((60 - moment().minutes()) * 60 * 1000);

  const template = ui.establishment.template;


  useEffect(()=>{
    if(template){
      dispatch({ type: "setProperty", property : "selectedDate", value: moment().format("YYYY-MM-DD")});
      dispatch({ type: "setProperty", property : "disabled", value: true});
      const _repas = sortRepas(template, Object.keys(template));
      setRepas(_repas);
      closestRepas();
    }
  },[template]);

  useEffect(()=>{
    closestRepas();
  },[repas]);


  useTimeoutWhen(()=> {
    setLoading(false);
  }, 2000, true);

  const closestRepas = () => {
    // changement de jour si jamais c'est nécessaire
    if(moment().format("YYYY-MM-DD") != ctx.selectedDate){
      dispatch({ type: "setProperty", property : "selectedDate", value: moment().format("YYYY-MM-DD")});
      dispatch({ type: "setProperty", property : "selectedRepas", value: repas[0]});
    }else{
      const time = moment().hours();
      let selectedRepas = null ;
      repas.forEach(_repas => {
        if(!selectedRepas && template[_repas].heure > time){
          selectedRepas = _repas;
        }
      });
  

      if(!selectedRepas){
        selectedRepas = repas[repas.length -1];
      }


  
      if(selectedRepas !== ctx.selectedRepas)
        dispatch({ type: "setProperty", property : "selectedRepas", value: selectedRepas});
    }

    setIntervalValue((60 - moment().minutes()) * 60 * 1000);

  };
  useInterval(closestRepas, intervalValue, true);

  const handleClick = (_repas) => {
    if(_repas !== ctx.selectedRepas )
      dispatch({ type: "setProperty", property : "selectedRepas", value: _repas});
  };

  if(!ctx && !ctx.selectedDate && !ctx.selectedRepas)return <Spinner/>;

  if(!ctx.data && loading)return <Spinner/>;


  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {template ? sortRepas(template, Object.keys(template)).map((_repas, id) => (
            <div key={id} className={`${styles.repasButton} ${_repas === ctx.selectedRepas ? styles.selected : null}`} onClick={()=> handleClick(_repas)}>
              {_repas}
            </div>
            // <div key={id} className={styles.repasComponentContainer}>
            //         <RepasComponent repas={_repas}/>
            // </div>
          )) : null}
      </div>
       

        <div className={styles.menu}>
          {ctx?.data && ctx?.data[ctx.selectedDate] ? 
            <>
                {isRepasEmpty(ctx.data[ctx.selectedDate][ctx.selectedRepas]) ?
                      <div className={styles.noMenu}>
                          Aucun menu défini pour le {ctx.selectedRepas} de ce jour
                      </div>
                  :  
                      <div className={styles.cardContainer}>
                          {sortMenus(template, ctx.selectedRepas, Object.keys(template[ctx.selectedRepas].menus)).map((menu, index) => (
                              !isMenuEmpty(ctx.data[ctx.selectedDate][ctx.selectedRepas], menu) ? 
                                  <div key={index}>
                                      <MenuCard repas={ctx.selectedRepas} menu={menu}/>
                                  </div>
                              : null
                          ))}
                      </div>
              }
            </>
          :  <div className={styles.noMenu}>
              Aucunes données pour ce jour.
          </div>}
              
        </div>
    </div>
  );

};


const TodayMenu = () => {
  return (
    <MenuProvider>
      <TodayMenuComponent/>
    </MenuProvider>
  );

};

export default TodayMenu;
