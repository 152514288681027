import React, {useContext} from "react";
import {Link} from "react-router-dom";
import Icon from "../Icon";
import PropTypes from "prop-types";
import { useRouteMatch } from "react-router-dom";
import {SidebarContext} from "../../context/dashboard/sidebar.context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "../../assets/scss/layout/dashboard/sidebarLink.module.scss";

const SidebarLink = (props) => {
  const {to, icon, iconAfter, children} = props;
  const [,setSidebarOpen] = useContext(SidebarContext);
  const match = useRouteMatch(to);

  const _isLinkActive = () =>  !(!match);

  const linkClick = () => {
    if (window.outerWidth <= 550) {
      setSidebarOpen(false);
    }
  };

  return (
    <Link to={to} className={`${styles.sidebarLink} ${_isLinkActive() ? styles.selected : ""} flex`} onClick={linkClick}>
      {icon ?
        <Icon size={20} 
          icon={icon}
          className="m-r-10 f-c-light inline-block	" />
        :
        <span className="m-r-15 m-l-5 f-w-900">•</span>
      }
      {children}
      {iconAfter ?
        <div className={styles.iconAfter}>
          <FontAwesomeIcon size={16} 
            icon={iconAfter}
            color={"#a680ad"}
          />
        </div>  
        :
        null
      }
    </Link>
  );
};

SidebarLink.propTypes = {
  icon: PropTypes.elementType,
  to: PropTypes.string.isRequired,
  iconAfter: PropTypes.any,
  children: PropTypes.string.isRequired
};

export default SidebarLink;