import React, {useEffect, useState} from "react";
import styles from "./truefalse.module.scss";
import { Circle, Triangle } from "react-feather";
import * as c from "../colors";
import { useParams } from "react-router-dom";
import useCultureQuizzInstance from "../../../../../hooks/useCultureQuizzInstance";
import useUI from "../../../../../hooks/ui.hook";

const Answering = () => {
  const [ui] = useUI();
  const params = useParams();
  const {
    instance,
    question,
    sendAnswer  } = useCultureQuizzInstance(params.uid);

  const [answer, setAnswer] = useState(null);

  useEffect(() => {
    if (instance && instance.answers && instance.answers[instance.currentQuestion] && instance.answers[instance.currentQuestion][ui.user.uid]){
      setAnswer(instance.answers[instance.currentQuestion][ui.user.uid].answer);
    }
  }, [instance]);

  const _onSendAnswer = (_answer) => {
    sendAnswer({
      answer: _answer,
      isCorrect: _answer === question.answer
    });
  };


  if(!question)return <></>;

  return (
    <div className={styles.answering}>
      <div className={styles.title}>
        <h3>{question.title}</h3>
      </div>
      <div className={styles.mediaDiv}>
        {question.media ? 
          <img src={question.media} className={styles.media} />
          : null}
      </div>
      <div className={styles.answers}>
        <div className={`${styles.answer}`} style={{backgroundColor : answer === true ? c.COLOR_QUIZZ_SELECTED : "white"}} onClick={()=> _onSendAnswer(true)}>
          <Circle stroke={c.COLOR_PALETTE_1[0]} width={70} height={70} fill={c.COLOR_PALETTE_1[0]}/>
          <span>VRAI</span></div>
        <div className={`${styles.answer}`} style={{backgroundColor : answer === false ? c.COLOR_QUIZZ_SELECTED : "white"}} onClick={()=> _onSendAnswer(false)}>
          <Triangle stroke={c.COLOR_PALETTE_1[1]} width={70} height={70} fill={c.COLOR_PALETTE_1[1]}/>
          <span>FAUX</span></div>
      </div>
    </div>
  );
};


export default Answering;