import React, { useEffect, useState, useMemo } from "react";
import { Spinner } from "../../../lib/components";
import { Card } from "../../../lib/components";

import styles from "../../../assets/scss/pages/Animation/customPlanning.module.scss";
import infoCardStyles from "../../../lib/assets/scss/component/infoCard.module.scss";
import useCategories from "../../../hooks/useCategories";
import useEvents from "../../../hooks/useEvents";
import useUI from "../../../hooks/ui.hook";
import moment from "moment";
import PropTypes from "prop-types";
import { MapPin, Calendar } from "react-feather";
import useAnimations from "./ContextAnimation";
import { InfoCard, InfoCardHeader, InfoCardBody } from "../../../lib/components/InfoCard";
import { toast } from "react-toastify";
import { CheckboxOutline, SquareOutline } from "react-ionicons";

import { sendNotifToMajor } from "services/notifications";
import firebase from "services/firebase";
const firestore = firebase.firestore;

const CustomPlanning = () => {
  const [ctx] = useAnimations();
  const { events, loading: eventsLoading } = useEvents();
  const { categories, loading: categoriesLoading } = useCategories();
  
  const rows = ["date", "matin", "soir"];
  
  if (!events || !categories ||!ctx.selectedDate || ctx?.dateDisplayArray?.length < 1) return <Spinner />;

  return (
    <table className={styles.container}>
      {rows.map((_id,indx) => (
        <tr key={indx} height={_id === "date" ? "35px" : "250px"}>
          {ctx.dateDisplayArray.map((_date,indx2) => (
            <td key={indx2} className={_date.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") ? styles.currentDay : null}>
              {_id === "date" ? 
               <Card className={styles.tableDate}>
                {_date.format("dddd DD MMMM")}
              </Card>
              :
              _id === "matin" ? 
                <Card className={styles.cards}>
                    {events.filter(_e => moment(_e.start.seconds * 1000).format("YYYY-MM-DD") === _date.format("YYYY-MM-DD"))
                    .filter(_e =>  moment(_e.start.seconds * 1000).hours() <= 12)
                    .filter((_e) => _e.isPublished || _e.isPublished === undefined || _e.isPublished === null)
                    .map((_e,indx) => (
                        <RenderEvent event={_e} categories={categories} key={indx}/>
                    ))}
                    {events.filter(_e => moment(_e.start.seconds * 1000).format("YYYY-MM-DD") === _date.format("YYYY-MM-DD"))
                    .filter(_e =>  moment(_e.start.seconds * 1000).hours() <= 12)
                    .filter((_e) => _e.isPublished || _e.isPublished === undefined || _e.isPublished === null)
                    .length < 1 ?
                      <div className={styles.noAnimation}>Aucune Animation</div>
                    : null}
                </Card> 
              :
              _id === "soir" ?
                <Card className={styles.cards}>
                    {events.filter(_e => moment(_e.start.seconds * 1000).format("YYYY-MM-DD") === _date.format("YYYY-MM-DD"))
                    .filter(_e =>  moment(_e.start.seconds * 1000).hours() > 12)
                    .filter((_e) => _e.isPublished || _e.isPublished === undefined || _e.isPublished === null)
                    .map((_e,indx) => (
                        <RenderEvent event={_e} categories={categories} key={indx}/>
                    ))}
                    {events.filter(_e => moment(_e.start.seconds * 1000).format("YYYY-MM-DD") === _date.format("YYYY-MM-DD"))
                    .filter(_e =>  moment(_e.start.seconds * 1000).hours() > 12)
                    .filter((_e) => _e.isPublished || _e.isPublished === undefined || _e.isPublished === null)
                    .length < 1 ?
                      <div className={styles.noAnimation}>Aucune Animation</div>
                    : null}
                </Card>
              :
              null
              }
            </td>
          ))}
        </tr>
      ))} 
    </table>
  );
};

const RenderEvent = ({event, categories}) => {
  const [ui] = useUI();
  const [ctx, dispatch] = useAnimations();
  const [eventState, setEventState] = useState();

  const SubscribeGuard = useMemo(() => {
    // User subscribed
    if (
      event.maxParticipants &&
      event.reservation &&
      event.reservation.includes(ui.user.uid)
    )
      return true;

    if (event.disableSubscribe) return false;

    // Too much participants
    if (
      event.maxParticipants &&
      event.reservation &&
      event.reservation.length >= event.maxParticipants
    )
      return false;

    
    // Subscribtions closed
    if (
      moment(event.start.seconds * 1000)
        .subtract(event.closeSubscribe, "days")
        .startOf("D")
        .isBefore(moment())
      && event.closeSubscribe != 0
    )
      return false;

    // Subscribe not opened
    if (
      moment(event.start.seconds * 1000)
        .subtract(event.openSubscribe, "days")
        .startOf("D")
        .isAfter(moment().startOf("D"))
      && event.openSubscribe != 0
    )
      return false;

    if (moment(event.start.seconds * 1000).isBefore(moment())) return false;

    return true;
  }, [event]);

  useEffect(() => {
    if(event && event !== eventState) {
      setEventState(event);
    }
  }, [event]);

  const toggleReservation = (type) => {
    updatePlanning(ui.user.establishment, event.uid, ui.user.uid, type);
  };

  const updatePlanning = async (establishmentId, eventId, uid, action) => {

    const _history = {app: "major-connect-web", action, date: firestore.Timestamp.now(), user: uid};

    console.log("cc");
    if (action == "add") {
      if (SubscribeGuard) {
        await firestore()
          .collection("establishments")
          .doc(establishmentId)
          .collection("blocks")
          .doc("planning")
          .collection("events")
          .doc(eventId)
          .update({
            reservation: firestore.FieldValue.arrayUnion(uid),
            history: firestore.FieldValue.arrayUnion(_history),
          });

        let _user = null;

        if (uid !== ui.user.uid) {
          let _userGet = await firestore().collection("users").doc(uid).get();
          _user = _userGet.data();
        } else {
          _user = ui.user;
        }

        toast.success("Réservation Réussie");
      }
    }

    if (action == "remove") {
      firestore()
        .collection("establishments")
        .doc(establishmentId)
        .collection("blocks")
        .doc("planning")
        .collection("events")
        .doc(eventId)
        .update({
          reservation: firestore.FieldValue.arrayRemove(uid),
          history: firestore.FieldValue.arrayUnion(_history),
        });

      // event.reservation.filter(participant => participant.uid != ui.user.uid);

      toast.success("Annulation Réussie");

    }

    if(["add", "remove"].includes(action)){
      sendNotifToMajor({
        ui,
        data : {
          type: "animation", // ["animation", "menu", "service", ...] 
          action: action.replace("remove", "delete"), // ["add", "update", "delete"]
          data: {
              userId: uid, // l'id de l'utilisateur qui a fait l'action 
              date: event?.start?.toDate(),
              name: event?.title, // repas, animation name, prestataire
              
              // specificités :
              category: _categories[event.categoryId]?.title, // catégorie de l'animations ou du service
              end: event?.end?.toDate(), //heure de fin du service / animation
          }
        }
      });
    }
  };
  
  const _categories = categories.reduce((acc, obj) => {const key = obj.uid;if(!acc[key])acc[key] = {};acc[key] = obj;return acc;},{});
    return (
      <InfoCard className={infoCardStyles.headband}>
          { event.reservation && event.maxParticipants != 0 && event.maxParticipants === event.reservation.length ?
            (<div style={{backgroundColor: "red", position: "absolute", zIndex: 99, transform: "translate(-125px, 30px) rotate(-45deg)", width: "100%", textAlign: "center", color: "white", fontWeight: "bold", fontSize: "18px"}}>COMPLET</div>)
            : null
          }
        <InfoCardHeader color={_categories[event.categoryId]?.color ?? "rgb(242, 153, 74)"}>
            {_categories[event?.categoryId]?.smiley ? 
                <img src={_categories[event.categoryId]?.smiley} style={{width: 25, height: 25}}></img> : <div></div>}
                <span>{_categories[event.categoryId]?.title}</span>
                <div className={styles.empty}></div>
        </InfoCardHeader>
        <InfoCardBody image={event.image} title={event.title} color={event.reservation && event.reservation.includes(ui.user.uid) ? "#b8d7b8" : "white"}>
              <div className={styles.infoBar} >
                  <div className={styles.infoContent} style={{gap: 10}}>
                    <Calendar size={22} />
                    <span className={styles.hours}>{moment(event.start.seconds * 1000).format("HH:mm")} - {moment(event.end.seconds * 1000).format("HH:mm")}</span>
                  </div>
                  {event.place ? 
                    <div className={styles.infoContent} style={{gap: 10}}>
                      <MapPin size={22} />
                      <span className="text-gray-800 text-xl font-italic">{event.place}</span>
                    </div>
                  : null}
                  {/* {event.maxParticipants ?
                    <div className={styles.infoContent} style={{gap: 10}}>
                      <Users size={22} />
                      <span className="text-gray-800 text-xl font-italic">{event.reservation?.length ?? 0} / {event.maxParticipants}</span>
                    </div>
                  : null} */}
                  {SubscribeGuard && ui.user.role === "senior" ? (<div className={styles.infoContent} style={{gap: 6}}>
                    {event.reservation && event.reservation.includes(ui.user.uid) ? 
                      <CheckboxOutline width={"24px"} height={"24px"} color={"green"} style={{cursor: "pointer"}} onClick={() => toggleReservation("remove")}/>
                      : 
                      <SquareOutline width={"24px"} height={"24px"} color={"black"} style={{cursor: "pointer"}} onClick={() => toggleReservation("add")}/>
                    }
                    <span className={styles.participationText}>participation</span>
                  </div>
                  ) : null}
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                  <div className={styles.button} onClick={()=> {
                    dispatch({ type: "setProperty", property : "eventModal", value: true});
                    dispatch({ type: "setProperty", property : "eventSelected", value: event.uid});
                  }}>voir plus</div>
                </div>
        </InfoCardBody>
      </InfoCard>
    );
  };


  RenderEvent.propTypes = {
    event: PropTypes.object,
    categories: PropTypes.object,
  
  };

export default CustomPlanning;
