import React from "react";
import {Modal as BootstrapModal,} from "reactstrap";
import Icon from "../Icon";
import PropTypes from "prop-types";
import {CloseSharp} from "react-ionicons";

import styles from "../../assets/scss/component/modal.module.scss";

const Modal = (props) => {
  const {children, className, toggle, zIndex} = props;

  return (
    <BootstrapModal
      {...props}
      className={`${styles.modal} ${className}`}
      zIndex = {zIndex ?? 1050}
    >
      {toggle && (
        <Icon
          icon={CloseSharp}
          size="28"
          color="dark"
          className={styles.close}
          onClick={toggle}
        />
      )}
      {children}
    </BootstrapModal>
  );
};

Modal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]),
  className: PropTypes.any,
  size: PropTypes.oneOf(["sm", "lg", "xl"]),
  zIndex: PropTypes.number,
};

Modal.defaultProps = {
  isOpen: false,
  size: "sm"
};

export default Modal;