import React, {useEffect, useState} from "react";

import moment from "moment";
import { ChevronLeft, ChevronRight, Circle } from "react-feather";
import useFeature from "hooks/useFeature";
import useAnimation from "./ContextAnimation";
import useUI from "hooks/ui.hook";
import styles from "assets/scss/pages/Menu/daySelector.module.scss";

const numEventDays = 4;


const AnimationHeader = () => {
  const [ctx, dispatch] = useAnimation();
  const numDays = ctx.daysToDisplay.length > 1 ? ctx.daysToDisplay.length : 7;


  useEffect(() => {
    changeWeek(1, true);
  }, [ctx.daysToDisplay, ctx.returnToday]);

  const changeWeek = (direction, returnToday) => {
    // direction = -1 || 1

    if(ctx.daysToDisplay.length === 1){
      let _dateArray = [];

      let _date = moment(ctx.selectedDate);
      if (returnToday) _date = moment();
      else if (ctx.dateArray.length > 1) {
        if (direction > 0) _date = ctx.dateArray[ctx.dateArray.length - 1].clone();
        if (direction < 0) _date = ctx.dateArray[0].clone();
      }
  
      if (!returnToday) _date.add(direction, "day");
  
      while (_dateArray.length < numDays) {
        if (ctx.daysToDisplay.indexOf(_date.weekday() + 1) != -1)
          _dateArray.push(_date.clone());
  
        _date.add(direction, "day");
      }
      if (direction < 0) _dateArray = _dateArray.reverse();
      dispatch({
        type: "setProperty",
        property: "week",
        value: _dateArray[0].clone(),
      });
      dispatch({
        type: "setProperty",
        property: "dateArray",
        value: _dateArray,
      });

      if (moment(_dateArray[0]).format("YYYY-MM-DD") != ctx.selectedDate) {
        dispatch({
          type: "setProperty",
          property: "selectedDate",
          value: _dateArray[0].format("YYYY-MM-DD"),
        });
        const _list = createDateList(_dateArray[0], ctx.daysToDisplay);
        dispatch({
          type: "setProperty",
          property: "dateDisplayArray",
          value: _list,
        });
      }
    }else{
      let _date = moment(ctx.selectedDate).add(direction, "week").startOf("week");
      if (returnToday) _date = moment().startOf("week");

      let _dateArray = [];

      ctx.daysToDisplay.forEach(dayId => {
        _dateArray.push(_date.clone().add(dayId-1, "days"));
      });

      dispatch({
        type: "setProperty",
        property: "week",
        value: _dateArray[0].clone(),
      });

      dispatch({
        type: "setProperty",
        property: "dateArray",
        value: _dateArray,
      });


      if(returnToday){
        
        const infoDateArray = _dateArray.map((d) => d.format("YYYY-MM-DD"));
        if (infoDateArray.includes(moment().format("YYYY-MM-DD"))) {
          dispatch({
            type: "setProperty",
            property: "selectedDate",
            value:  moment().format("YYYY-MM-DD"),
          });
          const _list = createDateList(moment(), ctx.daysToDisplay);
          dispatch({
            type: "setProperty",
            property: "dateDisplayArray",
            value: _list,
          });
        }else{
          dispatch({
            type: "setProperty",
            property: "selectedDate",
            value: _dateArray[0].format("YYYY-MM-DD"),
          });
          const _list = createDateList(_dateArray[0], ctx.daysToDisplay);
          dispatch({
            type: "setProperty",
            property: "dateDisplayArray",
            value: _list,
          });
        }
      }else {
        dispatch({
          type: "setProperty",
          property: "selectedDate",
          value: _dateArray[0].format("YYYY-MM-DD"),
        });
        const _list = createDateList(_dateArray[0], ctx.daysToDisplay);
        dispatch({
          type: "setProperty",
          property: "dateDisplayArray",
          value: _list,
        });
      }
    }
    
  };

  return (
    <div className={styles.headerBar} style={{backgroundColor: "unset"}}>
      <div className={styles.container}>
        <ChevronLeft
            style={{padding: 5, paddingRight: 0, cursor: "pointer" }}
            onClick={() => changeWeek(-1)}
            size={60}
            color={"#4A0956"}/>
        <div className={styles.dayContainer}>
          <RenderDays/>
        </div>
        <ChevronRight
            style={{padding: 5, paddingRight: 0, cursor: "pointer" }}
            onClick={() => changeWeek(1)}
            size={60}
            color={"#4A0956"}/>
      </div>

      <div
        className={styles.backToday}
        onClick={() => {
          changeWeek(1, true);
        }}>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        <span>Revenir à aujourd'hui</span>
      </div>

      {ctx.dateArray.length > 0 ? 
        <div className={styles.monthInfo}>
          {ctx.dateArray[0].month() === ctx.dateArray[ctx.dateArray.length -1].month() ? 
            ctx.dateArray[0].format("MMMM")
            :
          ctx.dateArray[0].format("MMMM") + " - " + ctx.dateArray[ctx.dateArray.length -1].format("MMMM")
          }
        </div>
      : null}
   
    </div>
  );
};

const RenderDays = () => {
  const [ctx, dispatch] = useAnimation();
  const [ui] = useUI();
  const isReservation = useFeature("reservationMenu");
  const userIsSenior =
    ui && ui.user && ui.user.role === "senior" ? true : false;

  const selectDate = id => {
    if (moment(ctx.dateArray[id]).format("YYYY-MM-DD") != ctx.selectedDate) {
      dispatch({
        type: "setProperty",
        property: "selectedDate",
        value: moment(ctx.dateArray[id]).format("YYYY-MM-DD"),
      });
    }

    const _list = createDateList(moment(ctx.dateArray[id]), ctx.daysToDisplay);
    dispatch({
      type: "setProperty",
      property: "dateDisplayArray",
      value: _list,
    });

  };


  let _dates = [];
  for (let i = 0; i < ctx.dateArray.length; i++) {
    let _date = moment(ctx.dateArray[i]);
    _dates.push(
      <div
        className={`${styles.day} ${_date.format("YYYY-MM-DD") === ctx.selectedDate ? styles.daySelected : null}`}
        key={_date.format("DD/MM/YY")}
        onClick={() => selectDate(i)}>
        <span className={styles.dayName}>
          {_date.format("ddd")}
        </span>
        <span className={styles.dayNumber}>
          {_date.format("DD")}
        </span>
      </div>,
    );
  }
  return _dates;
};

const createDateList = (date, daysToDisplay) => {
  let _dateArray = [];

  let _date = date.clone();
  while(_dateArray.length < numEventDays){
    if (daysToDisplay.indexOf(_date.weekday() + 1) != -1)
      _dateArray.push(_date.clone());

    _date.add(1, "day");
  }
  return _dateArray;
};  



export default AnimationHeader;
