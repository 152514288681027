import React, {useEffect, useState} from "react";
import firebase from "services/firebase";
import {toast} from "react-toastify";
import {Card, Form, FormGroup, Input, Button} from "lib/components";

import useCtx from "./context";

const firestore = firebase.firestore;

const Establishment = () => {
  const [ctx, dispatch] = useCtx();
  const [estErr, setEstErr] = useState(null);
  const [establishmentData, setEstablishmentData] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        if (ctx.establishment.length === 6) {
          let res = await firestore()
            .collection("establishments")
            .where("id", "==", ctx.establishment)
            .get();
          let establishments = [];

          res.forEach(doc => establishments.push({...doc.data(), id: doc.id}));
          if (establishments.length === 1) {
            setEstablishmentData(establishments[0]);
            dispatch({
              type: "changeEstablishmentId",
              establishmentId: establishments[0].id,
            });
          } else {
            dispatch({type: "changeEstablishmentId", establishmentId: null});
            setEstablishmentData(null);
          }
        } else {
          dispatch({type: "changeEstablishmentId", establishmentId: null});
          setEstablishmentData(null);
        }
      } catch (e) {
        toast.error(e?.message || "une erreur est survenue");
        console.error(e);
      }
    })();
  }, [ctx.establishment]);

  const setEstablishment = e => {
    setEstErr(null);
    dispatch({type: "changeEstablishment", establishment: e.target.value});
  };

  const onSubmit = async () => {
    setEstErr(null);
    if (ctx.establishmentId) dispatch({type: "navigate", step: "Register_Role"});
    else toast.error("Code établissement invalide");
  };

  return (
    <div className="flex justify-center items-center w-full" style={{height: "calc(100vh - 90px)"}}>
      <Card color="white">
        <h3 className="text-center">Inscription 1/4: Etablissement</h3>
        <p className="text-center">Renseignez le code établissement qui vous a été communiqué pour pouvoir créer votre compte</p>
        <FormGroup className="mt-3">
          <Input value={ctx.establishment}
                onChange={setEstablishment}
                placeholder="MAJOR1"
                />
          {establishmentData && (
            <div className="mt-4">
              <span className="font-bold">Votre établissement: </span><span>{establishmentData.name}</span>
            </div>
          )}
        </FormGroup>
        <FormGroup className="flex items-center justify-center">
          <Button onClick={onSubmit}>Suivant</Button>
        </FormGroup>
      </Card>
    </div>
  );
};

export default Establishment;
