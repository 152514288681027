import React, {useState} from "react";
import Tab from "../../../lib/components/Tab";

import Profile from "./Profile";
import Residence from "./Residence";
import UserList from "../UserList/UserList";


const ProfilePage = () => {
  return (
    <div style={{flex:1}}>
      <Profile/>
    </div>
  );
};

export default ProfilePage;