import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {ToastContainer} from "react-toastify";
import Router from "./Router";
import {Provider as UIProvider} from "./contexts/ui.context";
import {ErrorBoundary} from "react-error-boundary";
import RenderError from "lib/pages/RenderError";

import moment from "moment";
import "moment/dist/locale/fr";
const OurFallbackComponent = ({ error, componentStack, resetErrorBoundary }) => {
  return (
    <div>
      <h1>An error occurred: {error.message}</h1>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
};

const App = () => {
  useEffect(() => {
    moment.updateLocale("fr");
  }, []);
  
  return (
      <UIProvider >
        <>
          <ToastContainer pauseOnFocusLoss={false} />
          <ErrorBoundary FallbackComponent={RenderError}
            onError={(error, errorInfo) => {console.error(error, errorInfo);}}
            /*onReset={() => {}}*/>
            <Router />
          </ErrorBoundary>
        </>
      </UIProvider>
  );
};


OurFallbackComponent.propTypes = {
  error : PropTypes.object.isRequired,
  componentStack : PropTypes.any,
  resetErrorBoundary : PropTypes.func.isRequired,
};
export default App;