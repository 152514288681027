import React, {useState} from "react";

import {User} from "react-feather";

import {
  Card,
  Avatar,
  Col,
  Row,
  Button,
  Banner} from "../../lib/components";

import useUI from "../../hooks/ui.hook";
import { TodayMenu, TodayActivities, NextActivities, LastActu } from "./HomeWidgets/router_widgets";
import useKeyboardShortcut from "use-keyboard-shortcut";
import styles from "../../assets/scss/pages/home.module.scss";
import { useHistory } from "react-router-dom";

const roleMap = {"owner" : "propriétaire", "employee" : "employé", "family" : "famille", "senior" : "sénior", "seniorTotem" : "sénior Totem", "superadmin" : "administrateur"};

const Index = () => {
  const [ui] = useUI();
  const history = useHistory();
  const [crash, setCrash] = useState(false);
  
  useKeyboardShortcut(
    ["Shift", "P", "O", "U", "F"],
    () => {
      setCrash(true);
    }, { overrideSystem: false, ignoreInputFields: false, repeatOnHold: false }
  );
  useKeyboardShortcut(
    ["Shift", "C", "R", "A", "S", "H"],
    () => {
      throw new Error("💥 CABOOM 💥");
    }, { overrideSystem: false, ignoreInputFields: false, repeatOnHold: false }
  );
  useKeyboardShortcut(
    ["Shift", "B", "O", "U", "M"],
    async () => {
      throw new Error("💥 CABOOM ASYNC 💥");
    }, { overrideSystem: false, ignoreInputFields: false, repeatOnHold: false }
  );

  return (
    <>
      {crash ? <div>💥{ui.test.crash.render}</div> : <></>}
        <div className={styles.header}>
          <Card color="grey" className={styles.cardAccueil}>
                <div>
                  <Avatar img={ui.establishment.profileImage} size="md" firstname={ui.user.surname} lastname={ui.user.name}/>
                  <div>
                    <p  className={styles.bonjour}>Bonjour</p>
                    <p  className={styles.name}>{ui.user.surname} {ui.user.name}</p>
                  </div>
                </div>
              </Card>
              {/* <Card className={styles.cardEtab}>
                <p>ID d'établissement:</p>
                <p className={styles.etabId}>{ui.establishment.id}</p>
            </Card> */}
        </div>
      <Banner/>
      <Row className={styles.content}>
        
        <Col className={styles.col1}>
            <Card className={`${styles.cards} ${styles.cardAnimation}`}>
              <div className={styles.cardHeader}>Animations</div>
              <TodayActivities />
              <NextActivities />  
              <div className={styles.buttonContainer}><Button color="primary" onClick={()=> history.push("/dashboard/animations")} className={styles.button}>En découvrir plus</Button></div>
            </Card>
        </Col>
        
        <Col className={styles.col2}>
        <Card className={`${styles.cards} ${styles.cardMenu}`}>
          <div className={styles.cardHeader}>Menus du jour</div>
          <TodayMenu/>
          <div className={styles.buttonContainer}><Button color="primary" onClick={()=> history.push("/dashboard/menu")} className={styles.button}>En découvrir plus</Button></div>
        </Card>
      </Col>
      <Col className={styles.col3}>
          <Card className={`${styles.cards} ${styles.cardNews}`}>
            <div className={styles.cardHeader}>Dernières actualités</div>
            <LastActu />
            <div className={styles.buttonContainer}><Button color="primary" onClick={()=> history.push("/dashboard/news")} className={styles.button}>En découvrir plus</Button></div>
          </Card>
        </Col>
      </Row>
     
     
    </>
  );
};

export default Index;