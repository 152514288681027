import React from "react";
import {Provider} from "./context";

import Establishment from "./Establishment";
import Role from "./Role";
import Name from "./Name";
import Password from "./Password";

import useCtx from "./context";

const Register = () => {
  const [ctx, dispatch] = useCtx();
  const {step} = ctx;

  return (
    <>
      {step === "Register_Establishment" ? <Establishment /> : 
       step === "Register_Role" ? <Role /> :
       step === "Register_Name" ? <Name /> : 
       step === "Register_Password" ? <Password /> : <></>}
    </>
  );
};

const RegisterHOC = () => {
  return (
    <Provider>
      <Register />
    </Provider>
  );
};

export default RegisterHOC;
