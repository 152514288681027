import React, {useState, useEffect} from "react";
import styles from "../assets/scss/component/banner.module.scss";
import useUI from "../../hooks/ui.hook";
import Marquee from "react-fast-marquee";
import firebase from "services/firebase";

const firestore = firebase.firestore;

const Banner = () => {
  const [ui] = useUI();
  const [establishment, setEstablishment] = useState(null);

  useEffect(() => {
    (async () => {
      let establishmentData = [];
      firestore()
        .collection("establishments")
        .doc(ui.user.establishment)
        .onSnapshot((res) => {
          if (res.exists) {
            establishmentData = {uid: res.id, ...res.data()};
          }
          setEstablishment(establishmentData);
        });
    })()
  }, [ui.user.establishement]);

  return (
    <>
     {establishment?.banner ? 
          <div
          className={styles.bannerContainer}
          style={{backgroundColor: establishment?.bannerType === "info" ? "#4A919E" : establishment?.bannerType === "warning" ? "#f2994a" : establishment?.bannerType === "alert" ? "#FF7F7F" : "#4A919E" }}
          >
            <Marquee
              className={styles.banner}
              speed={45}
              autoFill={true}
            >
                {establishment?.banner} 
                {"       "}
            </Marquee>
          </div>
          
    : null}
    </>
  
  );
};

export default Banner;