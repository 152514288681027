import React, {useState} from "react";
import PropTypes from "prop-types";
import { Emoji } from "emoji-picker-react";
import EmojiPicker from "emoji-picker-react";
import styles from "../../assets/scss/component/smileyPicker.module.scss";

const SmileyPicker = (props) => {
  const {
    smiley,
    onAdd
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  const _onAdd = (e) => {
    onAdd(e.getImageUrl());
    setIsOpen(false);
  };

  return (
    <>
      {isOpen ?         <div style={{position: "fixed", top: 0, left: 0, bottom: 0, right: 0}} onClick={() => setIsOpen(false)}></div>
        : null}
      <div style={{marginLeft: 4}}>
        <div className={smiley ? styles.iconSelected : styles.icon } onClick={() => setIsOpen(!isOpen)}>
          {smiley ? 
            <img src={smiley} width="42px"></img>
            :
            <Emoji unified="1f603" size="42"/>
          }
        </div>
        <div style={{zIndex: 1000, position: "absolute", display: isOpen ? "block" : "none"}}>
          <EmojiPicker onEmojiClick={_onAdd} />
        </div>
      </div>
    </>
    
  );
};

SmileyPicker.propTypes = {
  smiley: PropTypes.string,
  onAdd: PropTypes.func.isRequired,
};

export default SmileyPicker;