import firebase from "firebase";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import "firebase/functions";
import "firebase/storage";

import MODE from "../env";

const EMULATOR_IP = "localhost";

var firebaseProdConfig = {
  apiKey: "AIzaSyCCk8amSwTXjaqzF495jZXOzl7trYns4yw",
  authDomain: "jabi-dev-6131f.firebaseapp.com",
  databaseURL: "https://jabi-dev-6131f-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "jabi-dev-6131f",
  storageBucket: "jabi-dev-6131f.appspot.com",
  messagingSenderId: "324164106624",
  appId: "1:324164106624:web:1f20461e785e7335ae6965",
  measurementId: "G-9STJ3G1YDK"
};

var firebaseDevConfig = {
  apiKey: "AIzaSyBhtua8LtC607gd7hwWER344b5ZYkEcoBw",
  authDomain: "major-dev-fdd82.firebaseapp.com",
  databaseURL: "https://major-dev-fdd82-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "major-dev-fdd82",
  storageBucket: "major-dev-fdd82.appspot.com",
  messagingSenderId: "319538589442",
  appId: "1:319538589442:web:b34d53b03028860057dc1b",
  measurementId: "G-6WTJXELYEP"
};

var firebasePreprodConfig = {
  apiKey: "AIzaSyA3oPpHgQW2aBaRzFym6omcUWPcUieymR0",
  authDomain: "major-preprod-4ae67.firebaseapp.com",
  databaseURL: "https://major-preprod-4ae67-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "major-preprod-4ae67",
  storageBucket: "major-preprod-4ae67.appspot.com",
  messagingSenderId: "861487893243",
  appId: "1:861487893243:web:5cc1da85e4f4199cdc3e5e"
};

console.log(MODE);
let rtd = null;

if (MODE === "PROD") {
  firebase.initializeApp(firebaseProdConfig);
} else if (MODE === "DEV") {
  firebase.initializeApp(firebaseDevConfig);
  rtd = firebase.database;
  console.log(firebase.database);

} else if (MODE === "PREPROD") {
  firebase.initializeApp(firebasePreprodConfig);
} else { //EMULATOR
  //firebase.initializeApp(firebaseProdConfig);
  //firebase.auth().useEmulator(`http://${EMULATOR_IP}:9099/`);
  //firebase.functions().useEmulator(`${EMULATOR_IP}`, 5001);
  //firebase.database().useEmulator(`${EMULATOR_IP}`, 9000);
  //firebase.firestore().useEmulator(`${EMULATOR_IP}`, 8080);
}

firebase.firestore().settings({ignoreUndefinedProperties: true});
export { rtd };
export default firebase;