import {useState, useEffect} from "react";
import firebase from "services/firebase";

import useUI from "./ui.hook";
import { toast } from "react-toastify";

const firestore = firebase.firestore;

const useCategories = (activeFetch = true) => {
  const [ui] = useUI();
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [categoriesRef, setCategoriesRef] = useState(null);

  useEffect(() => {
    (async () => {
      let blocks = [];
      let estBlocksQuery = await firestore().collection("establishments")
        .doc(ui.user.establishment)
        .collection("blocks")
        .get();
      estBlocksQuery.forEach(doc => blocks.push({uid: doc.id, ...doc.data()}));
      let planningBlock = blocks.filter(i => i.blockType === "plan");
      
      let _categoriesRef;
      if (!planningBlock.length) {
        await firestore().collection("establishments")
          .doc(ui.user.establishment)
          .collection("blocks")
          .doc("planning")
          .set({blockType: "plan"});
        _categoriesRef = firestore().collection("establishments")
          .doc(ui.user.establishment)
          .collection("blocks")
          .doc("planning")
          .collection("categories");
      } else {
        _categoriesRef = firestore().collection("establishments")
          .doc(ui.user.establishment)
          .collection("blocks")
          .doc(planningBlock[0].uid)
          .collection("categories");
      }
      setCategoriesRef(_categoriesRef);

      if (activeFetch) {
        let subscribe = _categoriesRef.onSnapshot(snap => {
          const data = snap.docs.map(doc => ({
            uid: doc.id,
            ...doc.data()
          }));
          setCategories(data);
          setLoading(false);
        });
        return subscribe;
      } else {
        setLoading(false);
        return null;
      }
    })();
  }, []);

  const categoryCreate = async (data) => {
    try {
      let res = await categoriesRef.add(data);
      await firestore().collection("establishments").doc(ui.user.establishment)
        .collection("topics").doc(res.id).set({
          title: data.title,
          categoryId: res.id,
          origin: "animationCategory",
        });
      return true;
    } catch (e) {
      console.error(e);
      toast.error(e.message);
      return false;
    }
  };

  const categoryUpdate = async (uid, data) => {
    try {
      await categoriesRef.doc(uid).update(data);
      await firestore().collection("establishments").doc(ui.user.establishment)
        .collection("topics").doc(uid).set({
          title: data.title,
          categoryId: uid,
          origin: "animationCategory",
        });
      return true;
    } catch (e) {
      console.error(e);
      toast.error(e.message);
      return false;
    }
  };

  const categoryDelete = async (uid) => {
    if (!window.confirm("Etes vous sur de vouloir supprimer cette catégorie ?")) return;
    try {
      let events = [];
      let blocks = [];
      let eventRef;
      let estBlocksQuery = await firestore().collection("establishments")
        .doc(ui.user.establishment)
        .collection("blocks")
        .get();

      estBlocksQuery.forEach(doc => blocks.push({uid: doc.id, ...doc.data()}));
      let planningBlock = blocks.filter(i => i.blockType === "plan");
      
      if (!planningBlock.length) {
        await firestore().collection("establishments")
          .doc(ui.user.establishment)
          .collection("blocks")
          .doc("planning")
          .set({blockType: "plan"});
        eventRef = firestore().collection("establishments")
          .doc(ui.user.establishment)
          .collection("blocks")
          .doc("planning")
          .collection("events");
      } else {
        eventRef = firestore().collection("establishments")
          .doc(ui.user.establishment)
          .collection("blocks")
          .doc(planningBlock[0].uid)
          .collection("events");
      }
      let resEvents = await eventRef.where("categoryId", "==", uid).get();
      resEvents.forEach(doc => events.push({uid: doc.id, ...doc.data()}));

      await Promise.all(events.map(async i => {
        await eventRef.doc(i.uid).update({"categoryId" : null});
      }));
      await categoriesRef.doc(uid).delete();

      await firestore().collection("establishments").doc(ui.user.establishment)
        .collection("topics").doc(uid).delete();
      return true;
    } catch (e) {
      console.error(e);
      toast.error(e.message);
      return false;
    }
  };

  return ({categories, loading, categoryCreate, categoryUpdate, categoryDelete});
};

export default useCategories;