import React from "react";
import PropTypes from "prop-types";
import useUI from "../../hooks/ui.hook";

import {
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import {
  DashboardLayout,
} from "../../lib/layouts";

import DashboardHeader from "../../config/DashboardHeader";
import MajorMenu from "../../config/MajorMenu";

import Home from "./Home";
import ProfilePage from "./Profile";
import UserSingle from "./UserList/UserSingle";
import NewsPage from "./News";
import NewsRead from "./News/NewsRead";

import Animation from "./Animation";
import PlanningWeekExport from "./Animation/PlanningWeekExport";
import PlanningMonthExport from "./Animation/PlanningMonthExport";

//import Ateliers from "./Ateliers";
//import Reservation from "./Reservation";
import Menu from "./Menu/Menu";

import Gazette from "./Gazette";
import GazetteView from "./Gazette/GazetteView";
import CultureQuizzQuizzList from "./CultureQuizz/QuizzList";
import CultureQuizzQuizzStart from "./CultureQuizz/QuizzStart";
import CultureQuizzQuizzPlay from "./CultureQuizz/QuizzPlay";
import CultureQuizzQuizzResults from "./CultureQuizz/QuizzResults";

import OnwerPopup from "./_OwnerPopup";

//import Notifications from "./Notifications";

//import Games from "./Games";

const MajorRouter = ({match}) => {
  const [ui] = useUI();

  return (
    <>
      <DashboardLayout menu={<MajorMenu />} header={<DashboardHeader />}>
        <Switch>
          <Route exact path={`${match.url}/one`}><Home /></Route>
          <Route exact path={`${match.url}/users/:id`}><UserSingle /></Route>
          <Route exact path={`${match.url}/profile`}><ProfilePage /></Route>

          {ui.establishment.features && ui.establishment.features.includes("menuPlanning") ? <Route exact path={`${match.url}/menu`}><Menu /></Route> : null}

          {ui.establishment.features && ui.establishment.features.includes("animationPlanning") ? <Route exact path={`${match.url}/animations`}><Animation /></Route> : null}
          {ui.establishment.features && ui.establishment.features.includes("animationPlanning") ? <Route exact path={`${match.url}/animations/weekExport`}><PlanningWeekExport /></Route> : null}
          {ui.establishment.features && ui.establishment.features.includes("animationPlanning") ? <Route exact path={`${match.url}/animations/monthExport`}><PlanningMonthExport /></Route> : null}
          
          {ui.establishment.features && ui.establishment.features.includes("news") ? <Route exact path={`${match.url}/news`}><NewsPage /></Route> : null}
          {ui.establishment.features && ui.establishment.features.includes("news") ? <Route exact path={`${match.url}/news/:id`}><NewsRead /></Route> : null}

          {ui.establishment.features && ui.establishment.features.includes("cultureQuizz") ? <Route exact path={`${match.url}/cultureQuizz`}><CultureQuizzQuizzList /></Route>: null}
          {ui.establishment.features && ui.establishment.features.includes("cultureQuizz") ? <Route exact path={`${match.url}/cultureQuizz/:uid/start`}><CultureQuizzQuizzStart /></Route> : null}
          {ui.establishment.features && ui.establishment.features.includes("cultureQuizz") ? <Route exact path={`${match.url}/cultureQuizz/:uid/play`}><CultureQuizzQuizzPlay /></Route> : null}
          {ui.establishment.features && ui.establishment.features.includes("cultureQuizz") ? <Route exact path={`${match.url}/cultureQuizz/:uid/results`}><CultureQuizzQuizzResults /></Route> : null}
      
          {ui.establishment.features && ui.establishment.features.includes("gazette") ? <Route path={`${match.url}/gazette/:uid/view`}><GazetteView /></Route> : null}
          {ui.establishment.features && ui.establishment.features.includes("gazette") ? <Route path={`${match.url}/gazette`}><Gazette /></Route> : null}

          <Route path={"*"}><Redirect to={`${match.url}/one`} /></Route>
        </Switch>
      </DashboardLayout>
      <OnwerPopup />
    </>
  );
};

MajorRouter.propTypes = {
  match: PropTypes.object.isRequired,
};

export default MajorRouter;