import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import styles from "./multipleChoice.module.scss";
import { Circle, Square, Star, Triangle } from "react-feather";
import * as c from "../colors";
import { useParams } from "react-router-dom";
import useCultureQuizzInstance from "../../../../../hooks/useCultureQuizzInstance";
import useUI from "../../../../../hooks/ui.hook";

const Answering = () => {
  const Icons = [
    <Circle key="circle" stroke={c.COLOR_PALETTE_1[0]} width={65} height={65} fill={c.COLOR_PALETTE_1[0]} />,
    <Square key="square" stroke={c.COLOR_PALETTE_1[1]} width={65} height={65} fill={c.COLOR_PALETTE_1[1]} />,
    <Star key="star" stroke={c.COLOR_PALETTE_1[2]} width={65} height={65} fill={c.COLOR_PALETTE_1[2]} />,
    <Triangle key="triangle" stroke={c.COLOR_PALETTE_1[3]} width={65} height={65} fill={c.COLOR_PALETTE_1[3]} />,
  ];

  const [ui] = useUI();
  const params = useParams();
  const {
    instance,
    question,
    sendAnswer  } = useCultureQuizzInstance(params.uid);

  const [answer, setAnswer] = useState(null);

  useEffect(() => {
    if (instance && instance.answers && instance.answers[instance.currentQuestion] && instance.answers[instance.currentQuestion][ui.user.uid]){
      setAnswer(instance.answers[instance.currentQuestion][ui.user.uid].answer);
    }
  }, [instance]);

  const _onSendAnswer = (_answer) => {
    sendAnswer({
      answer: _answer,
      isCorrect: _answer === question.answer
    });
  };


  if(!question)return <></>;

  return (
    <div className={styles.answering}>
      <div className={styles.title}>
        <h3>{question.title}</h3>
      </div>
      <div className={styles.mediaDiv}>
        {question.media ? <img src={question.media} className={styles.media} /> : null}
      </div>
      <div className={styles.answers}>
        {question.answers.map((_answer, index) => (
          <div
            key={index}
            className={`${styles.answer}`}
            onClick={()=> _onSendAnswer(index)}
            style={{backgroundColor : answer === index ? c.COLOR_QUIZZ_SELECTED : "white"}}
          >
            {Icons[index]}
            <span>{_answer}</span>
          </div>
        ))}
      </div>
    </div>
  );
};


export default Answering;
