import React, { useState, useEffect } from "react";
import useUI from "hooks/ui.hook";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Checkbox,
} from "lib/components";

import { useMenu } from "../../../router_context";
import deepClone from "helpers/deepClone";
import moment from "moment";
import {sortRepas} from "../../../helpers/operations";
import { CheckmarkSharp } from "react-ionicons";
import { toast } from "react-toastify";
import firebase from "services/firebase";

const ModalReservation = () => {
  const firestore = firebase.firestore;
  const [ui] = useUI();
  const [ctx, dispatch] = useMenu();

  const template = ui.establishment.template;

  const [reservations, setReservations] = useState([]);
  const [repas, setRepas] = useState([]);
  const [days, setDays] = useState([]);
  const [validation, setValidation] = useState(false);

  const formatDate = (date) => {
    return moment(date).format("dddd Do MMMM YYYY");
  };

  useEffect(() => {
    setDays(getPeriodReservations());
    
    setRepas(sortRepas(template, Object.keys(template)).filter(
      (repas, index) => index === 0 || index === 1
    ));
  }, []);

  useEffect(() => {
    const _uid = ui.user.uid;
    let _reservations = {}; 
    
    const _filteredDays = days.filter(day => ctx?.data[day]);
    _filteredDays.forEach((_day) => {
      _reservations[_day] = {};
      repas.forEach((_repasName) => {
        if (
          ctx.data[_day].reservation &&
          ctx.data[_day].reservation[_uid] &&
          ctx.data[_day].reservation[_uid][_repasName] 
        )           _reservations[_day][_repasName] = true;
        else{
        _reservations[_day][_repasName] = false;
        }
          
      });
    });
    setReservations(_reservations);
  }, [days, ctx.data]);

  useEffect(() => {
  }, [reservations]);

  const getDefaultRepas = (date, repasName) => {
    const _repas = repasName ?? ctx?.selectedRepas;
    if (
      !ctx.data ||
      !date ||
      !ctx.data[date] ||
      !ctx.data[date][_repas] ||
      !ui?.establishment?.template ||
      !ui?.establishment?.template[_repas]?.menus
    )
      return {};

    const dailyRepas = ctx.data[date][_repas];

    const _defaultMenus = Object.entries(
      ui.establishment.template[_repas].menus
    )
      .sort((a, b) => a[1].weight - b[1].weight)
      .map((i) => i[0]);

    const defaultResa = Object.entries(dailyRepas).map(([repas, value]) => {
      const defaultMenu = _defaultMenus.find((defaultMenu) => {
        const _defaultPlat = Object.entries(value)
          .filter(([, value]) => value.origin === defaultMenu)
          .sort(([, a], [, b]) => a.order - b.order)
          .map(([key, value]) => ({ key, ...value }));
        return _defaultPlat.length > 0;
      });
      return defaultMenu
        ? {
            repas,
            ...Object.entries(value)
              .filter(([, value]) => value.origin === defaultMenu)
              .sort(([, a], [, b]) => a.order - b.order)
              .map(([key, value]) => ({ key, ...value }))[0],
          }
        : { repas };
    });

    return defaultResa.reduce(
      (acc, { repas, ...rest }) => ({ ...acc, [repas]: rest?.key ?? null }),
      {}
    );
  };
  //aliases
  const dataDay = ctx?.data[ctx?.selectedDate];
  const _save = () => {
    ctx.updateMenu(reservations);
    dispatch({ type: "setProperty", property: "modalResaOpen", value: false });
    dispatch({ type: "setProperty", property: "modalInfos", value: null });
  };

  const resaValidation = () => {
      let _reservations = deepClone(reservations);
      let _modifications = {};
      let _uid = ui.user.uid;

      Object.keys(_reservations).forEach((date) => {
        Object.keys(_reservations[date]).forEach((_repasName) => {
          const _reservationParDefaut = getDefaultRepas(date, _repasName);

          if (_reservations[date][_repasName] === true) {
            // cas 1 : checkbox true
            if (
              ctx?.data[date]?.reservation &&
              ctx?.data[date]?.reservation[_uid] &&
              ctx?.data[date]?.reservation[_uid][_repasName]
            ) {
              // cas réservation dans la base de données et checkbox a true
              // ON fait rien
            } else {
              // cas checkbox a true et pas de réservation dans la base de données
              // besoin de rajouter
              if (!_modifications[date]) _modifications[date] = {};
              _modifications[date][_repasName] = _reservationParDefaut;
            }
          } else {
            // cas 2 : checkbox false
            if (
              ctx?.data[date]?.reservation &&
              ctx?.data[date]?.reservation[_uid] &&
              ctx?.data[date]?.reservation[_uid][_repasName]
            ) {
              // cas réservation dans la base de données et checkbox a false
              // Besoin de supprimer
              if (!_modifications[date]) _modifications[date] = {};
              _modifications[date][_repasName] = null;
            } else {
              // cas checkbox a false et pas de réservation dans la base de données
              // On fait rien
            }
          }
        });
      });
      
      setValidation(true);
      updateReservations(_modifications, _uid);
  };

  const updateReservations = async (modifications, _uid) => {
    const homeDelivery = false;
    const batch = firestore().batch();

    Object.keys(modifications).forEach((_day) => {
      let object = {};

      if (
        ctx.data[_day] &&
        ctx.data[_day].reservation &&
        ctx.data[_day].reservation[_uid]
      )
        object = ctx.data[_day].reservation[_uid];

      let needDelete = true;

      Object.keys(modifications[_day]).forEach((_repas) => {
        if (modifications[_day][_repas] !== null) {
          needDelete = false;

          object[_repas] = new Array(1).fill({
            homeDelivery,
            ...modifications[_day][_repas],
          });
        } else {
          if (object[_repas]) delete object[_repas];
        }
      });

      let map = {};

      if (needDelete && Object.keys(object) === 0) {
        map = {
          ["reservation." + _uid]: firestore.FieldValue.delete(),
        };
      } else {
        map = {
          ["reservation." + _uid]: object,
        };
      }

      const docRef = firestore()
        .collection("establishments")
        .doc(ui.user.establishment)
        .collection("blocks")
        .doc("menu")
        .collection("menu")
        .doc(_day);
      batch.update(docRef, map);
    });
    try {
        await batch.commit();
        toast.success("Réservation Réussie"); 
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
    }

  };
  
  const getPeriodReservations = () => {
    const result = [];
    for (let i=1; i<15; i++) {
      result.push(moment().add(i, "days").format("YYYY-MM-DD"));
    }
    return result;
  };

  const handleReservation = (day, meal) => {
    const stateCopy = deepClone(reservations);
    stateCopy[day][meal] = !stateCopy[day][meal];
    setReservations(stateCopy);
  };

  return (
    <Modal
      isOpen={ctx.modalResaOpen}
      toggle={() =>
        dispatch({
          type: "setProperty",
          property: "modalResaOpen",
          value: false,
        })
      }
      size="xl"
    >
      <ModalHeader>
        {validation
          ? `Récapitulatif à partir du ${formatDate(days[0])}`
          : `Réservations à partir du ${formatDate(days[0])}`}
      </ModalHeader>
      <ModalBody>
        {validation ? (
          <h2
            style={{
              textAlign: "center",
              marginBottom: "50px",
              color: "#008000",
            }}
          >
            Votre commande a bien été validée !
          </h2>
        ) : null}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <div style={{ fontSize: "20px", width: "18%" }}>Date</div>
          <div style={{ fontSize: "20px", width: "28%" }}>{repas[0]}</div>
          <div style={{ fontSize: "20px", width: "28%" }}>{repas[1]}</div>
        </div>
        <div
          style={{ overflowY: "scroll", height: "500px", overflowX: "hidden" }}
        >
          {Object.keys(reservations).map((day, index) => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                marginBottom: "40px",
                borderTop: "2px solid #dee2e6",
                paddingTop: "40px",
              }}
              key={index}
            >
              <div style={{ width: "28%", fontSize: "18px" }}>
                {formatDate(day)}
              </div>
              {validation ? (
                <div style={{ transform: "scale(1.2)", width: "27%" }}>
                  <CheckmarkSharp
                    color={"green"}
                    height="25px"
                    width="25px"
                    style={
                      reservations[day][repas[0]]
                        ? {}
                        : { visibility: "hidden" }
                    }
                  />
                </div>
              ) : (
                <div style={{ transform: "scale(1.2)", width: "28%" }}>
                  <Checkbox
                    label="Réserver"
                    checked={reservations[day][repas[0]]}
                    onChange={() => handleReservation(day, repas[0])}
                    style={{ fontSize: "20px" }}
                  />
                </div>
              )}

              {validation ? (
                <div style={{ transform: "scale(1.2)", width: "23%" }}>
                  <CheckmarkSharp
                    color={"green"}
                    height="25px"
                    width="25px"
                    style={
                      reservations[day][repas[1]]
                        ? {}
                        : { visibility: "hidden" }
                    }
                  />
                </div>
              ) : (
                <div style={{ transform: "scale(1.2)", width: "25%" }}>
                  <Checkbox
                    label="Réserver"
                    checked={reservations[day][repas[1]]}
                    onChange={() => handleReservation(day, repas[1])}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          {!validation ? (
            <div
              style={{
                width: "40%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              {dataDay ? (
                <Button
                  color="primary"
                  /*disabled={!hasChanged}*/ onClick={resaValidation}
                >
                  Valider
                </Button>
              ) : null}
              <Button
                color="secondary"
                onClick={() => {
                  dispatch({
                    type: "setProperty",
                    property: "modalResaOpen",
                    value: false,
                  });
                  setValidation(false);
                }}
              >
                Retour
              </Button>
            </div>
          ) : (
            <div>
              <Button
                color="secondary"
                onClick={() => {
                  dispatch({
                    type: "setProperty",
                    property: "modalResaOpen",
                    value: false,
                  });
                  setValidation(false);
                }}
              >
                Fermer
              </Button>
            </div>
          )}
        </div>
      </ModalFooter>
    </Modal>
  );
};

const SuspenseHOC = (props) => {
  const [ctx] = useMenu();

  if (!ctx || !ctx.data) return <></>;
  return <ModalReservation {...props} />;
};

export default SuspenseHOC;
