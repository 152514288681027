import React from "react";
import {useEffect, useRef, useState} from "react";
import { useLocation, useHistory } from "react-router";
import PDF from "./_monthPlanningPdf";
import {PDFDownloadLink} from "@react-pdf/renderer";
import {Card, Button} from "../../../lib/components";
import sleep from "../../../helpers/asyncWait";

const PlanningMotnExport = () => {
  const history = useHistory();
  const location = useLocation();
  const params = location.state;
  const dlButton = useRef(null);
  const [counter, setCounter] = useState(5);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    (async () => {
      await sleep(2000);
      if (dlButton.current) {
        dlButton.current.click();
        setRedirect(true);
      }
    })();
  }, []);

  useEffect(() => {
    if (!redirect) return;

    let interval = setInterval(() => {
      setCounter(counter - 1);
      if (counter === 0) {
        history.push("/dashboard/animations");
        clearInterval(interval);
      }
    }, 1000);
  }, [redirect, counter]);

  return (
    <Card style={{width: "100%", height: 800}}>
      <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
        <Button color="secondary" onClick={() => history.goBack()} style={{marginRight: 5}}>Retour</Button>
        <PDFDownloadLink 
          document={<PDF monday={params.monday} events={params.events} logo={params.logo}/>}
          fileName="planning.pdf">
          {({ /*blob, url,*/ loading/*, error */}) => (loading ? "Loading document..." : 
            <Button color="primary">Télécharger</Button>
          )}
        </PDFDownloadLink>
      </div>
      <PDF monday={params.monday} events={params.events} logo={params.logo} />
    </Card>
  );
};

export default PlanningMotnExport;