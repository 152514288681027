import React, {useEffect, useState} from "react";

import moment from "moment";
import { ChevronLeft, ChevronRight, Circle } from "react-feather";
import useFeature from "hooks/useFeature";
import { useMenu } from "../../router_context";
import useUI from "hooks/ui.hook";
import styles from "assets/scss/pages/Menu/daySelector.module.scss";
import { isDayEmpty, isRepasEmpty } from "../../helpers/operations";

const MenuHeader = () => {
  const [ctx, dispatch] = useMenu();
  const [DateArray, setDateArray] = useState([]);
  const numDays = ctx.daysToDisplay.length > 1 ? ctx.daysToDisplay.length : 7;

  useEffect(() => {
    changeWeek(1, true);
  }, [ctx.daysToDisplay, ctx.returnToday]);

  const changeWeek = (direction, returnToday) => {
    // direction = -1 || 1

    if(ctx.daysToDisplay.length === 1){
      let _dateArray = [];

      let _date = moment(ctx.selectedDate);
      if (returnToday) _date = moment();
      else if (DateArray.length > 1) {
        if (direction > 0) _date = DateArray[DateArray.length - 1].clone();
        if (direction < 0) _date = DateArray[0].clone();
      }
  
      if (!returnToday) _date.add(direction, "day");
  
      while (_dateArray.length < numDays) {
        if (ctx.daysToDisplay.indexOf(_date.weekday() + 1) != -1)
          _dateArray.push(_date.clone());
  
        _date.add(direction, "day");
      }
      if (direction < 0) _dateArray = _dateArray.reverse();
      dispatch({
        type: "setProperty",
        property: "week",
        value: _dateArray[0].clone(),
      });
      setDateArray(_dateArray);
      if (moment(_dateArray[0]).format("YYYY-MM-DD") != ctx.selectedDate) {
        dispatch({
          type: "setProperty",
          property: "selectedDate",
          value: _date.format("YYYY-MM-DD"),
        });
      }
    }else{
      let _date = moment(ctx.selectedDate).add(direction, "week").startOf("week");
      if (returnToday) _date = moment().startOf("week");

      let _dateArray = [];

      ctx.daysToDisplay.forEach(dayId => {
        _dateArray.push(_date.clone().add(dayId-1, "days"));
      });

      dispatch({
        type: "setProperty",
        property: "week",
        value: _dateArray[0].clone(),
      });
      setDateArray(_dateArray);
      if(returnToday){
        
        const infoDateArray = _dateArray.map((d) => d.format("YYYY-MM-DD"));
        if (infoDateArray.includes(moment().format("YYYY-MM-DD"))) {
          dispatch({
            type: "setProperty",
            property: "selectedDate",
            value:  moment().format("YYYY-MM-DD"),
          });
        }else{
          dispatch({
            type: "setProperty",
            property: "selectedDate",
            value: _dateArray[0].format("YYYY-MM-DD"),
          });
        }
      }else {
        dispatch({
          type: "setProperty",
          property: "selectedDate",
          value: _dateArray[0].format("YYYY-MM-DD"),
        });
      }
    }
    
  };

  return (
    <div className={styles.headerBar}>
      <div className={styles.container}>
        <ChevronLeft
            style={{padding: 5, paddingRight: 0, cursor: "pointer" }}
            onClick={() => changeWeek(-1)}
            size={60}
            color={"#4A0956"}/>
        <div className={styles.dayContainer}>
          <RenderDays DateArray={DateArray} />
        </div>
        <ChevronRight
            style={{padding: 5, paddingRight: 0, cursor: "pointer" }}
            onClick={() => changeWeek(1)}
            size={60}
            color={"#4A0956"}/>
      </div>

      <div
        className={styles.backToday}
        onClick={() => {
          changeWeek(1, true);
        }}>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        <span>Revenir à aujourd'hui</span>
      </div>
      {DateArray.length > 0 ? 
        <div className={styles.monthInfo}>
          {DateArray[0].month() === DateArray[DateArray.length -1].month() ? 
            DateArray[0].format("MMMM")
            :
          DateArray[0].format("MMMM") + " - " + DateArray[DateArray.length -1].format("MMMM")
          }
        </div>
      : null}
   
    </div>
  );
};

const RenderDays = ({DateArray}) => {
  const [ctx, dispatch] = useMenu();
  const [ui] = useUI();
  const isReservation = useFeature("reservationMenu");
  const userIsSenior =
    ui && ui.user && ui.user.role === "senior" ? true : false;
  const template = ui.establishment.template;
  
  const selectDate = id => {
    if (moment(DateArray[id]).format("YYYY-MM-DD") != ctx.selectedDate) {
      dispatch({
        type: "setProperty",
        property: "selectedDate",
        value: moment(DateArray[id]).format("YYYY-MM-DD"),
      });
    }
  };

  const reservationStatus = _date => {
    // -1 : les menus ne sont pas renseignés  0 : pas de résas, 1 : au moins 1 menu est réservé
    const _uid = ui.user.uid;
    let retour = 0;
    const _data = ctx.data[_date];
    const repasList = Object.keys(ui.establishment.template);

    if (isDayEmpty(template, _data)) retour = -1;
    else {
      if (_data.reservation && _data.reservation[_uid]) {
        let list = [];
        Object.keys(_data).forEach(repas => {
          if (repasList.includes(repas)) {
            if (!isRepasEmpty(_data[repas])) {
              if (_data.reservation[_uid][repas]) {
                if (_data.reservation[_uid][repas].length > 0) {
                  retour = 1;
                }
              }
            }
          }
        });
      }
    }
    return retour;
  };

  let _dates = [];
  for (let i = 0; i < DateArray.length; i++) {
    let _date = moment(DateArray[i]);
    let _resaStatus = reservationStatus(_date.format("YYYY-MM-DD"));
    _dates.push(
      <div
        className={`${styles.day} ${_date.format("YYYY-MM-DD") === ctx.selectedDate ? styles.daySelected : null}`}
        key={_date.format("DD/MM/YY")}
        onClick={() => selectDate(i)}>
        <span className={styles.dayName}>
          {_date.format("ddd")}
        </span>
        <span className={styles.dayNumber}>
          {_date.format("DD")}
        </span>
        {isReservation && userIsSenior && _resaStatus !== -1 ? (
            <Circle
             style={{position: "absolute", right: 1, top: 0}}
             size={15}
             fill={_resaStatus === 0
              ? "orange"
              : _resaStatus === 1
              ? "green"
              : "gray"}
             color={
                _resaStatus === 0
                  ? "orange"
                  : _resaStatus === 1
                  ? "green"
                  : "gray"
              }/>
        ) : null}
      </div>,
    );
  }
  return _dates;
};


export default MenuHeader;
