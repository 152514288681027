import { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import useUI from "../../hooks/ui.hook";

const useSentry = () => {
  const [ui] = useUI();
  const [isEnabled] = useState(window.location.hostname !== "localhost");

  useEffect(() => {
    if (isEnabled) {
      Sentry.init({
        dsn: "https://e67236b0c12d40c0a286b2fbb7e1ad90@o1133530.ingest.sentry.io/6180174",
        integrations: [],
        tracesSampleRate: 1.0,
      });
    }
  }, []);

  useEffect(() => {
    if (isEnabled) {
      Sentry.setUser({
        id: ui?.user?.uid ?? null,
        role: ui?.user?.role ?? null,
        establishmentId: ui?.establishment?.uid ?? null,
        establishmentName: ui?.establishment?.name ?? null,
        groupementId: ui?.groupement?.uid ?? null,
        groupementName: ui?.groupement?.name ?? null,
        specific: ui?.groupement?.specific ?? null
      });
    }
  }, [ui.establishment, ui.user]);
};

export default useSentry;