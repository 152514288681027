import React, {useState, useRef, useEffect} from "react";
import { useHistory } from "react-router-dom";
import { useFirebaseCollection } from "lib/hooks/firebase";
import useUI from "hooks/ui.hook";
import firebase from "services/firebase";
import { Button, CustomCarousel } from "../../../lib/components";
import styles from "assets/scss/pages/Gazette/gazettes.module.scss";
import { pdfjs, Document, Page } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const firestore = firebase.firestore;

const Gazette = () => {
  const history = useHistory();
  const [ui] = useUI();
  const [gazettesRef] = useState(firestore().collection("establishments").doc(ui.user.establishment).collection("gazettes"));
  const {data} = useFirebaseCollection(gazettesRef, []);
  const [height, setHeight] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    if(ref.current != null){
      setHeight(ref.current.clientHeight);
    }
  },[ref.current]);

  const filtered = data.filter((g) => g.publication != null).sort((a, b)=> b.createdAt.seconds - a.createdAt.seconds);
  const heightA4 = (height * 0.75);
  const widthA4 = heightA4 / 1.41553398;

  return (
    <>
      <div className={styles.container} ref={ref}>
      <CustomCarousel
          items={filtered}
          renderItem={({ item }) => (
            <div className={styles.gazetteObject} key={item.uid} onClick={() => history.push(`/dashboard/gazette/${item.uid}/view`)}
                style={{width: widthA4, height: heightA4, overflow: "hidden"}}>
               <div className={styles.pageShadow}></div>
               <Document
              file={item.publication}
              error={"Impossible de charger cette gazette"}
              // onLoadSuccess={onDocumentLoadSuccess}
               >
                <Page pageNumber={1} width={widthA4 - 1} />
               </Document>
               {/* <Button color="primary" size="sm" onClick={() => history.push(`/dashboard/gazette/${item.uid}/view`)}>Voir la gazette</Button>  */}
            </div>
          )}
          numberPerView={3}
          type={"basic"}
        />
      </div>
    </>
  );
};

export default Gazette;