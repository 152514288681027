import React, {useState} from "react";
import PropTypes from "prop-types";
import firebase from "services/firebase";
import styles from "../../assets/scss/component/imagePicker.module.scss";
import { toast } from "react-toastify";
import {Trash} from "react-feather";
import useUI from "../../../hooks/ui.hook";
import { v4 as uuidv4 } from "uuid";
import { Spinner } from "..";

const storage = firebase.storage;

const ImagePicker = (props) => {
  const {
    className,
    setImageLoaderForm,
    style,
    onChange,
    value,
    label,
    invalid,
    max,
    horizontal
  } = props;
  const [ui] = useUI();
  const [imageLoader, setImageLoader] = useState(false);

  const changeImageLoader = (bool) =>{
    setImageLoader(bool);
    if(setImageLoaderForm)
      setImageLoaderForm(bool);
  };
  const onAdd = async (e) => {
    changeImageLoader(true);
    e.preventDefault();
    try {
      if (typeof(value) === "string" || value == null) {
        let hash = uuidv4();
        await storage().ref(`/uploads/${ui.user.establishment}/${hash+e.target.files[0].name}`).put(e.target.files[0]);
        let url = await storage().ref(`uploads/${ui.user.establishment}`).child(hash+e.target.files[0].name).getDownloadURL();

        onChange(url);        
      } else if (typeof(value) == "object") {
        if(value){
          if (value.length + e.target.files.length > max) {
            changeImageLoader(false);
            toast.error(`${max} images maximum`);
            return;
          }
        }
        let urls = [];
        for (let i = 0; i < e.target.files.length; i++) {
          let hash = uuidv4();
          await storage().ref(`/uploads/${ui.user.establishment}/${hash+e.target.files[i].name}`).put(e.target.files[i]);
          urls.push(await storage().ref(`uploads/${ui.user.establishment}`).child(hash+e.target.files[i].name).getDownloadURL());
        }
        onChange([...value, ...urls]);
      } else {
        if (value && value.length + e.target.files.length > max) {
          toast.error(`${max} images maximum`);
          changeImageLoader(false);
          return;
        }else{
          if(e.target.files.length > max){
            toast.error(`${max} images maximum`);
            changeImageLoader(false);
            return;
          }
        }
        let urls = [];
        for (let i = 0; i < e.target.files.length; i++) {
          let hash = uuidv4();
          await storage().ref(`/uploads/${ui.user.establishment}/${hash+e.target.files[i].name}`).put(e.target.files[i]);
          urls.push(await storage().ref(`uploads/${ui.user.establishment}`).child(hash+e.target.files[i].name).getDownloadURL());
        }
        onChange([...urls]);
      }
    } catch (e) {
      changeImageLoader(false);
      console.error(e.message);
      toast.error(e.message);
    }
    changeImageLoader(false);
  };

  const onRemove = (e) => {
    if (e === null) {
      onChange(null);
    } else {
      onChange(value.filter(i => i !== e));
    }
  };

  return (
    <div className={styles.container} style={style}>
      <span className={styles.label}>{label} ({max > 1 ? value.length + "/" : "max "}{max} image{max > 1 ? "s" : ""})</span>
      <div className={styles.headerContainer}>
        <input type="file" placeholder="Sélectionner votre image" onChange={onAdd} multiple={max > 1}/>
        {imageLoader ? <Spinner color="primary" size="md"/>: null}
      </div>
      <div className={`${styles.imageContainer} ${horizontal ? styles.horizontal : styles.vertical}`}>
        {value && typeof(value) === "string" ?
          <div className={styles.image}>
            <span className={styles.icon}>
              <Trash size={24} color="red" onClick={() => onRemove(null)} />
            </span>
            <img src={value} />
          </div>
          :
          value && value.length > 0 ? <>
            {value.map(i => (
              <div key={i} className={styles.image}>
                <span className={styles.icon}>
                  <Trash size={24} color="red" onClick={() => onRemove(i)} />
                </span>
                <img src={i} />
              </div>
            ))}
          </> :
            <></>
        }
      </div>
    </div>
  );
};

ImagePicker.defaultProps = {
  style: {},
  invalid: false,
  multi: false,
};

ImagePicker.propTypes = {
  className : PropTypes.any,
  setImageLoaderForm : PropTypes.func,
  style : PropTypes.any,
  onChange : PropTypes.func,
  value : PropTypes.any,
  label : PropTypes.string,
  invalid : PropTypes.bool,
  max : PropTypes.number,
  horizontal : PropTypes.any,
};

export default ImagePicker;