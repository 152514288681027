import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import {Container, Row, Col, Spinner, Button, Select, FormGroup, Card, Input, Checkbox} from "lib/components";
import ImagePickerCrop from "lib/components/Form/ImagePickerCrop";
import useUI from "../../../hooks/ui.hook";
import firebase from "services/firebase";
import { toast } from "react-toastify";
import sleep from "lib/helpers/asyncWait";
const firestore = firebase.firestore;

const selectMenu = [{value:"binaryChoice", label: "par menu"},{value:"multipleChoice", label: "par plat"}];
const days = ["lun.", "mar.", "mer.", "jeu.", "ven.", "sam.", "dim."];

const Residence = () => {
  const [ui] = useUI();
  const [etab, setEtab] = useState(null);
  const [isReservationMenu, setIsReservationMenu] = useState(false);
  const [loadingEtab, setLoadingEtab] = useState(false);
  const [loadingMenu, setLoadingMenu] = useState(false);
  const [loadingPlanning, setLoadingPlanning] = useState(false);

  const [capacity, setCapacity] = useState(0);
  const [nafApe, setNafApe] = useState("");
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [siret, setSiret] = useState("");
  const [notifMail, setNotifMail] = useState("");
  const [menuType, setMenuType] = useState(selectMenu[0]);
  const [menuDelay, setMenuDelay] = useState(0);
  const [imageLoaderForm, setImageLoaderForm] = useState(false);
  const [menuOption, setMenuOption] = useState(false);
  const [menuDaysToDisplay, setMenuDaysToDisplay] = useState([1,2,3,4,5,6,7]);
  const [planningDaysToDisplay, setPlanningDaysToDisplay] = useState([1,2,3,4,5,6,7]);

  useEffect(() => {
    (async () => {
      try {
        let res = await firestore().collection("establishments").doc(ui.user.establishment).get();
        res = ({uid: res.id, ...res.data()});
        if(res.features.indexOf("menuPlanning")){
          let res2 = await firestore().collection("establishments").doc(ui.user.establishment).collection("blocks").doc("menu").get();
          let delay = res2.data().delay;
          //let _menuDaysToDisplay =  res2.data().daysToDisplay;
          if(delay)setMenuDelay(delay);
          //if(_menuDaysToDisplay)setMenuDaysToDisplay(_menuDaysToDisplay);
          if(res.features.indexOf("reservationMenu") != -1)
            setIsReservationMenu(true);
        }
        // if(res.features.indexOf("animationPlanning")){
        //   let res3 = await firestore().collection("establishments").doc(ui.user.establishment).collection("blocks").doc("planning").get();
        //   let _planningDaysToDisplay =  res3.data().daysToDisplay;
        //   if(_planningDaysToDisplay)setPlanningDaysToDisplay(_planningDaysToDisplay);
        // }
        setEtab(res);
      } catch (e) {
        console.error(e.message);
        toast.error(e.message);
      }
    })();
  }, []);

  useEffect(() => {
    if (etab === null) return;
    if(etab.capacity)
      setCapacity(etab.capacity);
    if(etab.nafApe)
      setNafApe(etab.nafApe);
    if(etab.id)
      setId(etab.id);
    if(etab.name)
      setName(etab.name);
    if(etab.phone)
      setPhone(etab.phone);
    if(etab.profileImage)
      setProfileImage(etab.profileImage);
    if(etab.siret)
      setSiret(etab.siret);
    if (etab.notifMail)
      setNotifMail(etab.notifMail);
    setMenuOption(etab.menuOption ?? false);

    // if(etab.menuType){
    //   let optionMenu = null;
    //   if(etab.menuType == "binaryChoice")optionMenu = selectMenu[0];
    //   else optionMenu = selectMenu[1];
    //   setMenuType(optionMenu);
    // }
  }, [etab]);

  const toggleMenuOption = (value) => {
    if (value === false && window.confirm("Êtes-vous sûr de vouloir désactiver les options de menu ? Cela supprimera tous les 2e choix de menu")) {
      setMenuOption(value);
    } else if (value === true) setMenuOption(value);
  };

  const submit = async () => {
    try {
      setLoadingEtab(true);
      let object = {};
      if(capacity)object.capacit = capacity;
      if(nafApe)object.nafApe = nafApe;
      if(name)object.name = name;
      if(phone)object.phone = phone;
      if(siret)object.siret = siret;
      if(profileImage){
        if (typeof(profileImage) === "object") {
          if(profileImage.length > 0){
            object.profileImage = profileImage[0];
          }
        } else{
          if(profileImage.length > 0){
            object.profileImage = profileImage;
          }
        }
      }

      await firestore().collection("establishments").doc(etab.uid).update(
        object
      );
      toast.success("Mise à jour réussie");
    } catch (e) {
      console.error(e.mesage);
      toast.error(e.message);
    }
    setLoadingEtab(false);
  };
  const submitNotifMail = async () => {
    try {
      await firestore().collection("establishments").doc(etab.uid).update({
        notifMail: notifMail
      });
      toast.success("Mise à jour réussie");
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
  };
  const submitMenu = async () => {
    try {
      setLoadingMenu(true);
      // await firestore().collection("establishments").doc(etab.uid).update({
      //   menuType : menuType.value,
      //   menuOption
      // });
      await firestore().collection("establishments").doc(etab.uid).collection("blocks").doc("menu").update({
        delay : menuDelay,
       // daysToDisplay : menuDaysToDisplay,
      });
      toast.success("Menu mis à jour");
      // toast.success("Menu mis à jour, l'application va redémarrer");
      // await sleep(5000);
      // window.location.href = "/";
    } catch (e) {
      console.error(e.mesage);
      toast.error(e.message);
    }
    setLoadingMenu(false);
  };
  // const submitPlanning = async () => {
  //   try {
  //     setLoadingPlanning(true);
  //     await firestore().collection("establishments").doc(etab.uid).collection("blocks").doc("planning").update({
  //       daysToDisplay : planningDaysToDisplay,
  //     });
  //   } catch (e) {
  //     console.error(e.mesage);
  //     toast.error(e.message);
  //   }
  //   setLoadingPlanning(false);
  // };
  
  const handleDaySelection = (type, day) => {
    let _selection = type=="menu" ? [...menuDaysToDisplay] : [...planningDaysToDisplay] ;
    let _index = _selection.indexOf(day);
    if(_index == -1){
      _selection.push(day);
    }else{
      _selection.splice(_index,1);
    }
    _selection.sort((a,b) => a-b);
    type=="menu" ? setMenuDaysToDisplay(_selection) : setPlanningDaysToDisplay(_selection);
  };
  const DaySelector = ({type}) => { // type : menu || planning
    let _retour = [];
    let _selection = type=="menu" ? menuDaysToDisplay : planningDaysToDisplay ;
    for(let i=1; i<=7; i++){
      _retour.push(<div style={_selection.indexOf(i) != -1 ? 
        {padding: 10, borderRadius: 25, marginRight: 20, backgroundColor: "#300438", cursor: "pointer"} :
        {padding: 10, borderRadius: 25, marginRight: 20, backgroundColor: "#300438", cursor: "pointer", opacity: 0.35}}
      onClick={() => handleDaySelection(type, i)}
      >
        <span style={{color: "white"}}>
          {days[i-1]}
        </span>
      </div>);
    }

    return <div style={{display: "flex", flexDirection: "row",alignItems: "center", justifyContent: "flex-start"}}>
      {_retour}
    </div>;
  };

  DaySelector.propTypes = {
    type: PropTypes.oneOf(["menu", "planning"]),
  };
  if (etab === null) return <Spinner />;

  return (
    <Container><Row><Col>
      <Card>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        <h4>Profil d'établissement</h4>
        <div>
          <FormGroup>
            <Input label="Nom d'établissement"
              value={name}
              onChange={e => setName(e.target.value)} />
          </FormGroup>
          <FormGroup style={{display: "flex", justifyContent: "center"}}>
            <ImagePickerCrop value={profileImage} onChange={setProfileImage} ratio={1/1} />
          </FormGroup>
        </div>
        <div style={{marginTop: 10, display: "flex", alignItems: "center", justifyContent: "center"}}>
          <Button color="primary"
            onClick={submit}
            loading={loadingEtab}
            disabled={imageLoaderForm}>Enregistrer</Button>
        </div>
      </Card>

      <Card>
        <h4>Notifications</h4>
        {/* eslint-disable-next-line react/no-unescaped-entities*/}
        <p>Pour être prévenu des inscriptions aux évènements du planning d'animation ou du menu.<br/></p>
        <Input type="text" placeholder="Ex: nom.prenom@mail.fr,nom2.prenom2@mail.fr" value={notifMail} onChange={e => setNotifMail(e.target.value)} />
        <div style={{marginTop: 10, marginBottom: -10, display: "flex", justifyContent: "center"}}>
          <Button color="primary" onClick={submitNotifMail}>Enregistrer</Button>
        </div>
      </Card>
      {isReservationMenu ? 
        <Card>
          <h4>Menus</h4> 
          
            <>
              {/* <FormGroup>
                <Select label="Système de réservation"
                  options={selectMenu}
                  value={menuType}
                  onChange={setMenuType}/>

              </FormGroup> */}
              <FormGroup>
                <Input label="Délai limite avant réservation (nombre de jours)"
                  type="number"
                  min={0}
                  value={menuDelay}
                  onChange={e => setMenuDelay(parseInt(e.target.value))} />
              </FormGroup> 
            </>  
            
          {/* <FormGroup>
            <h5>Jours activés :</h5>
            <DaySelector type="menu"/>
          </FormGroup>
          <FormGroup>
            <Checkbox label="Options de plat multiple ?" checked={menuOption} onChange={() => toggleMenuOption(!menuOption)} />
          </FormGroup> */}
          <div style={{marginTop: 10, display: "flex", alignItems: "center", justifyContent: "center"}}>
            <Button color="primary"
              onClick={submitMenu}
              loading={loadingMenu}
            >Enregistrer</Button>
          </div>
        </Card>
      : null}
      {/* <Card>
        <h4>Animations</h4> 
        <h5>Jours activés :</h5>
        <DaySelector type="planning"/>
        <div style={{marginTop: 10, display: "flex", alignItems: "center", justifyContent: "center"}}>
          <Button color="primary"
            onClick={submitPlanning}
            loading={loadingPlanning}
          >Enregistrer</Button>
        </div>
      </Card> */}
    </Col></Row></Container>
  );
};



export default Residence;