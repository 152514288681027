import React, { useState } from "react";

import Button from "../Button";
import PropTypes from "prop-types";
import firebase from "services/firebase";
import styles from "../../assets/scss/component/unsplashPicker.module.scss";

const functions = firebase.functions;

const UnsplashPicker = (props) => {
  const { title, onChange, style, url } = props;
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [selected, setSelected] = useState(null);
  const [searching, setSearching] = useState(null);

  const _onSearch = async () => {
    try {
      setLoading(true);
      let _search = search.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      let res = await functions().httpsCallable("unsplashSearch")({
        search: _search,
      });
      setImages(res.data.results);
      setSearching(_search);
      setSearch("");
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  const _onSelect = (image) => {
    setSelected(image);
    if (onChange) onChange(image);
  };

  const _renderImage = (image) => (
    <div
      key={image.id}
      className={styles.image}
      onClick={() => {
        if (selected && selected.id === image.id) _onSelect(null);
        else _onSelect(image);
      }}
    >
      <div
        className={`${styles.checkbox} ${
          selected && selected.id === image.id ? styles.selected : ""
        }`}
      ></div>
      <img src={image.urls.small} />
    </div>
  );

  return (
    <div className={styles.container} style={style}>
      <span className={styles.label}>{title}</span>
      <div className={styles.headerContainer}>
        <input
          type="text"
          placeholder="Noël, Anniversaire, ..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Button onClick={_onSearch} loading={loading}>
          Parcourir...
        </Button>
        {searching || url !== null ? (
          <Button
            onClick={() => (searching ? setSearching(null) : _onSelect(null))}
            color="danger"
          >
            X
          </Button>
        ) : (
          <></>
        )}
      </div>
      {searching ? (
        <div className={styles.imagesContainer}>
          {images && images.length ? images.map(_renderImage) : <></>}
        </div>
      ) : url !== null && typeof url === "string" ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={url} style={{ marginTop: 10 }} />
        </div>
      ) : url !== null && url.image && url.image.urls ? (
        <img src={typeof url === "string" ? url : url.image.urls.small} />
      ) : (
        <></>
      )}
    </div>
  );
};

UnsplashPicker.defaultProps = {
  title: "image",
  style: {},
};

UnsplashPicker.propTypes = {
  title: PropTypes.string,
  onChange: PropTypes.func,
  style: PropTypes.any,
  url: PropTypes.string,
};

export default UnsplashPicker;
