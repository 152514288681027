import React, {Fragment} from "react";
import PropTypes from "prop-types";

import styles from "../assets/scss/component/infoCard.module.scss";

const InfoCard = ({height, children, className}) => {
    const heightClass = height === "small"   ? styles.small   :
                        height === "normal" ? styles.normal :
                        height === "large"   ? styles.large  :
                        height === "x-large"   ? styles.x_large  : styles.normal;

return (
  <div className={`${styles.card} ${heightClass} ${className ?? null}`}>
    {children}
  </div>);
};

const InfoCardHeader = ({color, className, title, tag, children}) => (
    <div className={`${styles.header} ${className ?? null}`} style={ color ? {backgroundColor: color} : null}>
        {title ? <>
            <div></div>
            <div className={styles.title}>{title}</div>
            {tag ? 
            <div className={styles.tag} style={{backgroundColor: tag === "national" ? "#E49F9F" : tag === "local" ? "#F9DEC9" : "#99B2DD"}}>
            {tag === "national" ? "Actualité Nationale" : tag === "local" ? "Actualité Locale" : "Actualité Régionale"}</div>
            : <div></div>}
        </>
        : children}
    </div>
);

const InfoCardBody = ({image, title, color, children}) => (
    <div className={styles.body} style={{backgroundColor: color}}>
        {image !== undefined ? 
            <div className={styles.imgContainer}>
                <img src={image}/>
            </div>
        : null}
        <div className={styles.content}>
            <div className={styles.title}>{title}</div>
            <div className={styles.children}>
                {children}
            </div>
        </div>
    </div>
);

InfoCard.propTypes = {
    height : PropTypes.oneOf(["small", "normal", "large"]),
    className: PropTypes.any,
    children : PropTypes.any,
};

InfoCardHeader.propTypes = {
    color : PropTypes.string,
    className: PropTypes.any,
    title: PropTypes.string,
    children: PropTypes.any,
    tag: PropTypes.string,
  };
  
  InfoCardBody.propTypes = {
    image : PropTypes.string,
    title: PropTypes.string,
    color : PropTypes.string,
    children : PropTypes.any,
  };

export {InfoCard,InfoCardHeader,InfoCardBody};