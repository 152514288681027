import React, { useState, useEffect, useMemo, useCallback } from "react";
import useUI from "hooks/ui.hook";
import {
    Button,
    Checkbox,
    FormGroup,
    Select
} from "lib/components";
import { useMenu } from "../../router_context";
import PropTypes from "prop-types";
import { sortDishes } from "pages/Major/Menu/helpers/operations";

import styles from "../Assets/scss/dishCard.module.scss";

const DishCard = (props) => {
    const { selected, dishId, dishInfo, handleClick, disabled } = props;

    const textSize = useMemo(() => {

        if(dishInfo.name.length > 20){
            const newSize =  1.1 - Math.round((dishInfo.name.length / 19)*100)/1000;  // Que le hasard soit, et le hasard fut.
            if(newSize < 0.55) return "0.55vw";
            return newSize+"vw";
        }else{
            return null;
        }
    },[dishInfo]);

    return (
        <div className={`${styles.container} ${selected ? styles.selected : null} ${disabled ? styles.disabled : null} `} onClick={() => !disabled ? handleClick(dishId) : null}>
            <div style={{fontSize: textSize ?? "unset"}}>
                {dishInfo.name}
            </div>
        </div>
    );
};


DishCard.propTypes = {
    selected: PropTypes.bool,
    dishId: PropTypes.string,
    dishInfo: PropTypes.object,
    handleClick: PropTypes.func,
    disabled: PropTypes.bool,
};


export default DishCard;
