import React from "react";
import PropTypes from "prop-types";

import { useMenu } from "../../router_context";
import styles from "assets/scss/pages/Menu/menuCard.module.scss";
import { Button } from "lib/components";
import useUI from "hooks/ui.hook";
import useFeature from "hooks/useFeature";
import {sortDishes, filterDishByMenu} from "../../helpers/operations";
import { CheckmarkSharp, CheckmarkCircleOutline } from "react-ionicons";


const MenuCard = ({repas, menu}) => {
    const [ctx, dispatch] = useMenu();
    const [ui] = useUI();

    const isSenior = ui.user.role === "senior";
    const isReservation = useFeature("reservationMenu");
    const template = ui.establishment.template;

    const _uid = ui.user.uid;

    const hasBookedDish = (category, dishId) => {
        let retour = false;
        
        if(
        ctx?.data[ctx?.selectedDate]?.reservation &&
        ctx?.data[ctx?.selectedDate]?.reservation[_uid] &&
        ctx?.data[ctx?.selectedDate]?.reservation[_uid][repas] &&
        ctx?.data[ctx?.selectedDate]?.reservation[_uid][repas].length >
          0 ){
            if(ctx?.data[ctx.selectedDate]?.reservation[_uid][repas][0][category] === dishId)retour = true;
          }

        return retour;
    };
    return (
        <div className={styles.container}>
            <div className={styles.title}>
                {menu}
            </div>                
            <div className={styles.separator2}></div>
            <div className={styles.categContainer}>
                {template[repas].menus[menu].categories.map((category, indx) => (
                    ctx?.data[ctx.selectedDate] &&  ctx?.data[ctx.selectedDate][repas] && ctx?.data[ctx.selectedDate][repas][category] && filterDishByMenu(Object.entries(ctx.data[ctx.selectedDate][repas][category]),menu).length > 0 ? 
                        <div key={indx} className={styles.categoryDiv}>
                            <span className={styles.categoryTitle}>{category}</span>
                            <div className={styles.dishContainer}>
                                {sortDishes(filterDishByMenu(Object.entries(ctx.data[ctx.selectedDate][repas][category]),menu)).map(([id, dish], index) => (
                                    <>
                                    {isReservation && !ctx.disabled ? 
                                        hasBookedDish(category, id) ?
                                        <div key={index} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                            <div style={{backgroundColor: "#b8d7b850", fontWeight: "bold", display: "flex", alignItems: "center", justifyContent: "center", paddingLeft: 5, paddingRight: 5, marginLeft: -25}}>
                                            <CheckmarkSharp
                                                color={"green"} 
                                                height="25px"
                                                width="25px"
                                                />
                                                {dish.name}
                                            </div>
                                            
                                        </div> :
                                        <div key={index}>
                                            {dish.name}
                                        </div>
                                    : 
                                        <div key={index}>
                                            {dish.name}
                                        </div>
                                    }
                                    </>
                                ))}
                            </div>
                            <div className={styles.separator}></div>
                        </div>
                    : null
                ))}

            </div>
        </div>
    );
};


MenuCard.propTypes = {
  repas: PropTypes.string.isRequired,
  menu: PropTypes.string.isRequired,
};

export default MenuCard;