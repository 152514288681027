import React from "react";
import PropTypes from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Grid, EffectCoverflow } from "swiper";
import styles from "../assets/scss/component/CustomCarousel.module.scss";
import "swiper/swiper-bundle.css";


const CustomCarousel = (props) => {
  const {items, renderItem, numberPerView, type} = props;

  if(!items) return <></>;
  if(items.length === 0) return <></>;

  let modules = [];

  switch (type) {
    case "basic":
        modules.push(Pagination);
        modules.push(Navigation);
        break;
  
    case "grid":
        modules.push(Grid);
        modules.push(Pagination);
        modules.push(Navigation);
        break;

    case "image":
        modules.push(EffectCoverflow);
        modules.push(Pagination);
        modules.push(Navigation);
        break;

    default:
        modules.push(Pagination);
        modules.push(Navigation);
        break;
  }
  return (
    <div className={styles.swiperContainer}>
        <Swiper
        slidesPerView={numberPerView}
        grid={type === "grid" ?{
            rows: 2,
          } : null}
        effect={type === "image" ? "coverflow" : ""}
        coverflowEffect={type === "image" ?{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          } : null}
        centeredSlides={type === "image" ? true : false}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={modules}
        className={styles.swiper}
      >
        {items.map((item, i) =>
         <SwiperSlide key={i} className={`${styles.swiperSlide} ${type === "grid" ? styles.grid : ""}  ${type === "image" ? styles.image : ""}`}>
            {renderItem({
            item,
            index: i
            })}
         </SwiperSlide>
         
        )}
      </Swiper>
    </div>
  );
};

CustomCarousel.propTypes = {
    items: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    renderItem: PropTypes.func,
    numberPerView: PropTypes.any,
    type: PropTypes.string,
};


export default CustomCarousel;