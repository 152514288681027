import React from "react";
import useUI from "../hooks/ui.hook";
import { Button } from "../lib/components";

const WrongApp = () => {
  const [ui, dispatch] = useUI();

  return (
    <div className="h-screen w-full flex flex-col items-center justify-center">
      <h2>WrongApp</h2>
      <Button onClick={() => dispatch({type: "logout"})}>Logout</Button>
    </div>
  );
};

export default WrongApp;