import React, { useState, useEffect, useMemo, useCallback } from "react";
import useUI from "hooks/ui.hook";
import moment from "moment";
import PropTypes from "prop-types";
import { useMenu } from "../../router_context";
import styles from "../Assets/scss/modalInformations.module.scss";
import { Tag } from "lib/components";

const colors = ["#8c66dc", "#dc6681", "#00bfb2", "#DC965A", "#81171B"];
const colorUnite = "#123865";

const ModalInformations = ({ reservation, page }) => {
    const [ui] = useUI();
    const [ctx, dispatch] = useMenu();

    // const dataDay = ctx?.data[ctx?.selectedDate];
    const currentUser = ui.user;
    const template = ui.establishment.template;
    const templateSubscription = ui.establishment.templateSubscription;
    const subscription = currentUser?.subscription && currentUser?.subscriptionMonths && currentUser?.subscriptionMonths.includes(moment(ctx?.selectedDate).format("YYYY-MM")) ? currentUser?.subscription : null;

    const supplements = useMemo(() => {
        const _supp = [];

        if (template && templateSubscription && subscription && templateSubscription[subscription].repas[ctx.modalInfos.repas]) {
            // s'il y a un abonnement pour ce repas.

            // maintenant on check tous les plats qui sont réservés alors qu'ils ne sont pas dans l'abonnement.
            template[ctx.modalInfos.repas].categories.forEach(_plat => {
                if (reservation[0][_plat] && !templateSubscription[subscription].repas[ctx.modalInfos.repas].includes(_plat)) _supp.push(_plat);
            });
        }
        return _supp;
    }, [ctx, reservation]);

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.title}> Formule : </div>
                {
                    template[ctx.modalInfos.repas].categories.map((_categ, indx) => (
                        reservation[page] && reservation[page][_categ] ? <div key={indx}>{_categ} </div> : null
                    ))
                }
            </div>
            {page === 0 && subscription ?
                <div className={styles.content}>
                    <div className={styles.title}> Supplément <span className={styles.priceUp}>(€)</span> : </div>
                    {
                        supplements.map((_categ, indx) => (
                            <div key={indx}>{_categ} </div>
                        ))
                    }
                </div>
                : null}
        </div>
    );
};


ModalInformations.propTypes = {
    reservation: PropTypes.array,
    page: PropTypes.number,
};

export default ModalInformations;
