import { Spinner } from "../../../lib/components";
import { Col, Container, Row } from "../../../lib/components";
import React, { useEffect, useRef, useState } from "react";

import CategoriesCrud from "./_categoriesCrud";
import EventModal from "./EventModal";
import styles from "../../../assets/scss/pages/animations.module.scss";
import CustomPlanning from "./CustomPlanning";
import useAnimations, { Provider } from "./ContextAnimation";
import AnimationHeader from "./DaySelector"; 

const Animations = () => {
  const [ctx] = useAnimations();
  const [eventOpen, setEventOpen] = useState(null);

  useEffect(() => {
    if(eventOpen){
      const _event = ctx.events.find((i) => i.uid === eventOpen.uid);
      if(_event){
        setEventOpen(_event);
      }
    }
  }, [ctx.events]);

  if (!ctx) return <Spinner />;

  return (
    <div className={styles.container}>
      <div className={styles.animation}>
        <Container>
          <Row className={styles.header}>
            <CategoriesCrud />
          </Row>
          <Row>
            <AnimationHeader/>
          </Row>
          <Row>
            <Col lg={12} style={{ marginTop: 20 }}>
              <CustomPlanning />
            </Col>
          </Row>
        </Container>
      </div>
      <EventModal/>
    </div>
  );
};

const AnimationsComp = () => (
    <Provider>
      <Animations />
    </Provider>
);


export default AnimationsComp;
