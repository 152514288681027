import React, { useState, useEffect } from "react";
import firebase, {rtd} from "services/firebase";
import { Card, Button } from "../../../lib/components";
import { Edit3, Play, Plus, Trash } from "react-feather";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import useUI from "../../../hooks/ui.hook";
import sleep from "lib/helpers/asyncWait";

const firestore = firebase.firestore;

const QuizzList = () => {
  const [ui] = useUI();
  const history = useHistory();

  const [activeQuizzes, setActiveQuizzes] = useState([]);

  console.log(ui);
  
  //fetch active quizzes in rtd
  useEffect(() => {
    const ref = rtd()
      .ref("quizz")
      .on("value", snapshot => {
        // filtrer les quizzes qui attendent des participants.
        let _quizzes = snapshot.val() ?? [];
        let _quizzFiltered = {};

        Object.entries(_quizzes).forEach(element => {
          if (element && element[1]) {
            if (
              (element[1].establishment === ui.user.establishment) || //establishment scope
              (ui?.groupement && element[1].establishment === "0" && element[1].region === ui.establishment.region && element[1].groupement === ui.groupement.uid) || //region scope
              (ui?.groupement && element[1].establishment === "0" && element[1].region === "0" && element[1].groupement === ui.groupement.uid) || //groupement scope
              (element[1].establishment === "0" && element[1].region === "0" && element[1].groupement === "0")
            ) {
              if (element[1].status === "waiting_participants") {
                _quizzFiltered[element[0]] = element[1];
              } else {
                if (ui && element[1].participants != undefined) {
                  if (
                    Object.values(element[1].participants).indexOf(
                      ui.user.uid,
                    ) != -1
                  ) {
                    _quizzFiltered[element[0]] = element[1];
                  }
                }
              }
            }
          }
        });

        setActiveQuizzes(_quizzFiltered);
      });
    return () => {
      rtd().ref("quizz").off("value", ref);
    };
  }, []);



  return (
    <>
      <div
        style={{ borderBottom: "1px solid grey", width: "100%", marginTop: 15, paddingBottom: 15 }}
      >
        <h3>Parties en cours:</h3>
        <div style={{ display: "flex", gap: 10 }}>
          {Object.keys(activeQuizzes).length === 0 ? (
            <h6>Aucune partie en cours</h6>
          ) : (
            Object.keys(activeQuizzes).map((uid) => (
              <Card key={uid} style={{display: "flex", flexDirection: "column", alignItems: "center", gap: 8}}>
                <span style={{ marginRight: 10, fontWeight: "bold", fontSize: 20 }}>
                  {activeQuizzes[uid].title ?? "Quizz sans nom"}
                </span>
                <Button style={{ cursor: "pointer", display: "flex", justifyContent: "space-around", alignItems: "center", paddingLeft : 8, paddingRight: 8 }} onClick={() =>
                      history.push(
                        `/dashboard/cultureQuizz/${uid}/${
                          activeQuizzes[uid].status === "waiting_participants" ? "start" : "play"
                        }`
                      )
                    }>
                    <span style={{marginRight: 6}}>{activeQuizzes[uid].status == "waiting_participants"
                        ? "Rejoindre"
                        : "Reprendre"}</span>
                  <Play/>
                </Button>
               
              </Card>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default QuizzList;
