import React, {useState} from "react";
import { Button } from "../../lib/components";
import useUI from "../../hooks/ui.hook";
import { X } from "react-feather";

const OwnerPopup = () => {
  const [ui] = useUI();
  const [open, setOpen] = useState(localStorage.getItem("ownerPopupSeen") !== "true");

  const close = () => {
    localStorage.setItem("ownerPopupSeen", "true");
    setOpen(false);
  };

  if (!open) return <></>;
  if (![ "owner", "admin" ].includes(ui.user.role)) return <></>;
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center " style={{zIndex: 1000}}>
      <div className="absolute top-0 left-0 right-0 bottom-0 flex bg-black opacity-20"></div>
      <div className="bg-white rounded-lg p-4 relative" style={{zIndex: 1001, maxWidth: "60%"}}>
        <div className="absolute top-4 right-4">
          <X size={32} className="cursor-pointer" onClick={close} />
        </div>
        <h1 className="text-2xl font-bold mr-10">Vous êtes connecté à la vue senior </h1>
        <p>
          Vous vous êtes connecté a major connect en tant que propriétaire d&apos;établissement.Vous pouvez visualiser ce que voient vos résidents, mais vous ne pouvez pas éditer le contenu.
        </p>
        <p>
          Pour accéder à la vue propriétaire, veuillez cliquer sur le bouton ci-dessous.
        </p>
        <div className="flex justify-center mt-3 flex-wrap" style={{gap: 10}}>
          <Button color="secondary" onClick={close}>Rester sur la vue Senior</Button>
          <Button onClick={() => window.href = "https://app.jabi.fr"}>Accéder a la vue propriétaire (Major)</Button>
        </div>
      </div>
    </div>
  );
};

export default OwnerPopup;