import {useState, useEffect} from "react";
import firebase from "services/firebase";
import { useHistory } from "react-router-dom";
import sleep from "../helpers/asyncWait";
import useUI from "./ui.hook";

const firestore = firebase.firestore;
const rtd = firebase.database;

const useCultureQuizzInstance = (instanceId) => {
  const history = useHistory();
  const [ui] = useUI();

  const [instance, setInstance] = useState(null);
  const [quizz, setQuizz] = useState(null);
  const [question, setQuestion] = useState(null);

  const [instanceLoading, setInstanceLoading] = useState(true);
  const [quizzLoading, setQuizzLoading] = useState(true);

  // register for instance rtd updates
  useEffect(() => {
    rtd().ref(`quizz/${instanceId}`).on("value", snapshot => {
      setInstance(snapshot.val());
      setInstanceLoading(false);
    });
  }, [instanceId]);

    // fetch quizz data
    useEffect(() => {
      (async () => {
        let _instance = (await rtd().ref(`quizz/${instanceId}`).once("value")).val();
        let _quizz = await firestore().collection("quizz").doc(_instance.quizzId).get();
        let _questions = await firestore().collection("quizz").doc(_instance.quizzId)
          .collection("questions").get();
        _quizz = {uid: _instance.quizzId, ..._quizz.data()};
        _questions = _questions.docs.map(q => ({uid: q.id, ...q.data()}));
        _quizz.questions = [..._questions];
        setQuizz(_quizz);
        setQuizzLoading(false);
      })();
    }, [instanceId]);

    //handle quizz abort
    useEffect(() => {
      if (instance && instance.status === "aborted")
        history.push("/dashboard/cultureQuizz");
      if (instance && instance.status === "started")
        history.push(`/dashboard/cultureQuizz/${instanceId}/play`);
      if (instance && instance.status === "results")
        history.push(`/dashboard/cultureQuizz/${instanceId}/results`);
    }, [instance]);

  // get question data
  useEffect(() => {
    if (!instance || instance.status !== "started" || !quizz) {
      setQuestion(null);
      return;
    } else {
      let _question = quizz.questions.find(q => q.uid === `${instance.currentQuestion}`);
      if (!_question) {
        history.push(`/dashboard/cultureQuizz/${instanceId}/results`);
      } else {
        setQuestion(_question);
      }
    }
  }, [instance, quizz]);

  const registerParticipant = async () => {
    if (!instance) return;
      let _participantsIds = Object.keys(instance.participants ?? []).map(p => instance.participants[p]);
      if (_participantsIds.find(p => p === ui.user.uid) !== undefined) return;
      await rtd().ref(`quizz/${instanceId}/participants`).push(ui.user.uid);
  };

  const unRegisterParticipant = async () => {
    if (!instance) return;
    const listRef = firebase.database().ref(`quizz/${instanceId}/participants`);

      // Récupérer une copie de la liste actuelle
      listRef.once("value", function(snapshot) {
        let list = snapshot.val();
        let _participantsIds = Object.entries(instance.participants ?? []);
        const _id = _participantsIds.find(([id, uid]) => uid === ui.user.uid);
        if(_id){
          delete list[_id[0]];
          listRef.set(list);
        }
      });
  };
  
  const sendAnswer = async (answer) => {
    try {
      await rtd().ref(`quizz/${instanceId}/answers/${instance.currentQuestion}/${ui.user.uid}`).set(answer);
    } catch (e) {
      console.error(e.message);
    }
  };


  return ({
    loading: instanceLoading || quizzLoading,
    instance,
    quizz,
    question,
    sendAnswer,
    registerParticipant,
    unRegisterParticipant
  });
};

export default useCultureQuizzInstance;