import React from "react";

import {LogOutOutline, SettingsOutline, ScanOutline} from "react-ionicons";
import Icon from "../lib/components/Icon";
import useUI from "../hooks/ui.hook";
import {useHistory} from "react-router-dom";
import { useFullscreen } from "rooks";
// import Notification from "./_Notification";
import styles from "../assets/scss/config/SuperAdminMenu.module.scss";

const Header = () => {
  const [ui, uiReducer] = useUI();
  const history = useHistory();
  const {exit, request, isFullscreen} = useFullscreen();

  const onLogout = () => {
    uiReducer({type: "logout"});
  };
  const toggle = () => {
    if (isFullscreen) exit();
    else request();
  };

  return (
    <>
      {ui.user.superadmin ? 
        <span className={styles.warning} style={{marginRight: 10, cursor: "pointer"}}
          onClick={() => window.location.href = "/"}>
          SUPERADMIN MODE
        </span>: null}
      {ui.user.groupementAdmin ? 
        <span className={styles.warning} style={{marginRight: 10, cursor: "pointer"}}
          onClick={() => window.location.href = "/"}>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          Retour a l'administration du groupement
        </span>
        : null}
      <div className="flex items-center" style={{gap: 10}}>
        {/* <Notification /> */}
        {/* <Icon onClick={() => toggle()} size={30} className="p-r-10" icon={ScanOutline}/> */}
        <Icon onClick={() => history.push("/dashboard/profile")}
          size={30} className="p-r-10" icon={SettingsOutline} />
        <Icon onClick={onLogout}
          size={30} className="p-r-10" align="right" icon={LogOutOutline} />
      </div>
    </>
  );
};

export default Header;