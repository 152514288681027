import "../../../assets/scss/components/imagegalery.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { Button, Spinner } from "../../../lib/components";
import { ChevronLeft, ChevronRight } from "react-feather";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { Carousel } from "react-responsive-carousel";
import {Modal, ModalBody, ModalFooter} from "../../../lib/components";
import moment from "moment";
import styles from "../../../assets/scss/pages/news.module.scss";
import useNews from "../../../hooks/useNews";

const NewsRead = () => {
  const { data, loading } = useNews();
  const history = useHistory();
  const { id } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  if (loading || !data || data.length === 0) return <Spinner />;

  const _news = data.find((_n) => _n.uid === id);
  if (!_news) return <Spinner />;

  const images = [_news?.image, ..._news?.galery]
    .filter((i) => i)
    .map((i) => ({
      original: i,
      thumbnail: i,
    }));

  const handleClickItem = (index) => {
    console.log(index);
    setSelectedImage(images[index].original);
    console.log(images[index].original);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
    setShowModal(false);
  };

  return (
    <div className={styles.absContainer}>

      <div className={styles.readContainer}>
                <Button className={styles.buttonRetour} onClick={()=>history.goBack()}>retour</Button>

        <div className={styles.pageContent}>

          <div className={styles.newsContainer}>
           
            {_news ? (
              <div>
                <div className={styles.tagContainer} style={_news.image ? {} : {top: 15}}>
                 <div className={styles.tag} style={{backgroundColor: _news.type === "national" ? "#E49F9F" : _news.type != undefined ? "#F9DEC9" : "#99B2DD"}}>
                    {_news.type === "national" ? "Actualité Nationale" : _news.type != undefined ? "Actualité Régionale" : "Actualité Locale"}
                  </div>
                </div>
                {_news.image ?
                <>
                  <div className={styles.headerImage}>
                    <img src={_news.image}></img>
                  </div>
                  <div className={styles.headerMargin}></div>
                </>
                
                : null }
                <div className={styles.header}>
                  <h1>{_news.title}</h1>
                  <div className={styles.date}>
                    Publié le {moment(_news?.date.toDate()).format("L")}
                  </div>
                </div>
              
                <div className={styles.newsDescription}>
                  <span className={styles.centerDescription}>
                    {_news.description}
                  </span>
                </div>
              </div>
              ) : null}
          </div>

          {images.length ? (
            <div className={styles.carouselContainer}>
              <Carousel
                onClickItem={handleClickItem}
                className={styles.carousel}
                autoPlay
                renderArrowPrev={(clickHandler, hasPrev) => {
                  return (
                    <div
                      className={`${
                        hasPrev ? "absolute" : "hidden"
                      } top-0 bottom-0 left-0 flex justify-center items-center p-3 opacity-60 hover:opacity-100 cursor-pointer z-20`}
                      onClick={clickHandler}
                    >
                      <ChevronLeft
                        style={{ padding: 5, paddingRight: 0, cursor: "pointer" }}
                        size={100}
                      />
                    </div>
                  );
                }}
                renderArrowNext={(clickHandler, hasNext) => {
                  return (
                    <div
                      className={`${
                        hasNext ? "absolute" : "hidden"
                      } top-0 bottom-0 right-0 flex justify-center items-center p-3 opacity-60 hover:opacity-100 cursor-pointer z-20`}
                      onClick={clickHandler}
                    >
                      <ChevronRight
                        style={{ padding: 5, paddingRight: 0, cursor: "pointer" }}
                        size={100}
                      />
                    </div>
                  );
                }}
              >
                {images.map((image, index) => (
                  <div className={styles.carouselImg} key={index}>
                    <img src={image.thumbnail} />
                  </div>
                ))}
              </Carousel>
              <Modal
                className={styles.modal}
                isOpen={showModal} 
                toggle={handleCloseModal} 
              >
                <ModalBody className={styles.modalBody}>
                  <img src={selectedImage} />
                </ModalBody>
                <ModalFooter className={styles.modalFooter}>
                  <Button onClick={handleCloseModal}>Fermer</Button>
                </ModalFooter>
              </Modal>
            </div>
          ) : (
            <></>
          )}

        </div>
        
      </div>
    </div>
  );
};

export default NewsRead;
